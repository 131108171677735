import React, { useState, useEffect, useCallback, useRef } from "react";
import { useGLTF } from "@react-three/drei/core/useGLTF";
import { useLoader, useThree } from "@react-three/fiber";
import { TextureLoader } from "three";
import { useSpring, animated, config } from "@react-spring/three";
import gradient from "../../assets/images/linearGradient.png";
import displayColorTexture from "../../assets/images/display_sprite_color.jpg";
import displayTransmissionTexture from "../../assets/images/display_transmission.png";
import {
  HousingMaterial,
  BrushedMetalMaterial,
  LogoMaterial,
  ButtonMaterial,
} from "../materials";
import {
  useHashLocation,
  useSpeed,
  useButtons,
  useDisplay,
  useBrushhead,
  useMaterial,
  useTranslatedText,
} from "../hooks";
import model from "../../assets/models/211103_housing.glb";
import { order } from "../pages/Display.jsx";
import { Html } from "@react-three/drei/web/Html";
import { Line } from "@react-three/drei";
import { A11yAnnouncer, A11y, useA11y } from "@react-three/a11y";
import { isMobile } from "react-device-detect";

function Housing({ rotation = [0, 0, 0], position, thresholds = [4.8, 5.2] }) {
  const { nodes } = useGLTF(model, "./draco/gltf/");

  const [TEXT] = useTranslatedText();

  const [emissive, setEmissive] = useState("rgb(255, 255, 255)");

  const [dotEmissive, setDotEmissive] = useState(0);

  const [location] = useHashLocation();

  const [speed] = useSpeed();

  const [power, setPower, world] = useButtons();

  const [display, setDisplay] = useDisplay();

  const [brush] = useBrushhead();

  const [material] = useMaterial();

  const { size, camera } = useThree();

  const a11y = useA11y();

  const spring = useSpring({
    from: {
      rotation: [0, 0, 0],
      position: [0, 0, 0],
      emissiveColor: "rgb(0, 0, 0)",
      dotEmissive: 0,
      envMapIntensity: 0,
      power: 0,
    },
    to: {
      rotation: rotation,
      position: position,
      emissiveColor: emissive,
      dotEmissive: dotEmissive,
      envMapIntensity: world,
      power: power,
    },
    config: config.molasses,
  });

  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);

  useEffect(() => setDotEmissive(1), []);

  const [, setSpeed] = useSpeed();

  useEffect(() => setSpeed(power === 0 ? 0 : 4), [power]);

  useEffect(() => {
    if (location !== "SENSOR_HEAD") return;
    if (speed <= thresholds[0]) setEmissive("rgb(255,255,255)");
    if (speed > thresholds[0] && speed <= thresholds[1])
      setEmissive("rgb(0,255,0)");
    if (speed > thresholds[1]) setEmissive("rgb(255,0,0)");
  }, [speed, location, thresholds]);

  useEffect(() => {
    world > 0
      ? document.body.classList.add("power-on")
      : document.body.classList.remove("power-on");
  }, [world]);

  const [gradientTexture, displayColorMap, displayTransmissionMap] = useLoader(
    TextureLoader,
    [gradient, displayColorTexture, displayTransmissionTexture]
  );

  useEffect(() => {
    displayColorMap.repeat.x = 1 / 6;
    displayColorMap.repeat.y = 1 / 4;

    displayColorMap.offset.x = (1 / 6) * SCREENS[display][1];
    displayColorMap.offset.y = (1 / 4) * SCREENS[display][0];
  }, [displayColorMap, display]);

  const activateSound = useRef(document.getElementById("activateSound"));
  const activateTwinkSound = useRef(
    document.getElementById("activateTwinkSound")
  );

  return (
    <>
      {location === "DISPLAY_HEAD" && (
        <group position={isMobile ? [0, 8.35, 1.3] : [-2, 9.4, 1.3]}>
          {!isMobile && (
            <Line
              points={[0, 0, 0, 1.33, 0, 0]}
              color={"white"}
              lineWidth={3}
              resolution={[size.width, size.height]}
            />
          )}
          <Html style={{ background: "red" }}>
            <div
              style={{
                position: "absolute",
                right: "1rem",
                top: 0,
                transform: "translate(0, -50%)",
                width: "14rem",
                color: "white",
                fontSize: "larger",
                textAlign: "right",
              }}
            >
              <p className={"mb-0"}>{TEXT.DISPLAY_MODE_PROMPT}</p>
            </div>
          </Html>
        </group>
      )}

      <group dispose={null}>
        <mesh geometry={nodes.Display.geometry}>
          <animated.meshStandardMaterial
            //color={'red'}
            map={displayColorMap}
            map-flipY={false}
            emissiveIntensity={spring.power}
            emissive={"white"}
            emissiveMap={displayColorMap}
            emissiveMap-flipY={false}
            envMapIntensity={spring.power}
          />
        </mesh>

        <mesh geometry={nodes.Bluetooth_Logo.geometry}>
          <LogoMaterial
            color={brush === 1 || material === 1 ? 0x999999 : 0xffffff}
          />
        </mesh>

        <mesh geometry={nodes.Light_Shine.geometry}>
          <animated.meshPhysicalMaterial
            emissive={spring.emissiveColor}
            emissiveIntensity={spring.power}
            envMapIntensity={spring.envMapIntensity}
            color={spring.emissiveColor}
            alphaMap={gradientTexture}
            transparent={true}
          />
        </mesh>

        <mesh geometry={nodes.Light.geometry}>
          <animated.meshPhysicalMaterial
            emissive={spring.emissiveColor}
            emissiveIntensity={spring.power}
            envMapIntensity={spring.envMapIntensity}
            color={spring.emissiveColor}
          />
        </mesh>

        <mesh geometry={nodes.Deco_Ring.geometry}>
          <BrushedMetalMaterial />
        </mesh>

        <mesh geometry={nodes.Soft_Mid.geometry}>
          <HousingMaterial />
        </mesh>

        <A11y
          disabled={
            location == "DISPLAY_HEAD" ||
            location == "TECHNOLOGY_HEAD" ||
            location == "BRUSHHEAD_HEAD" ||
            location == "SENSOR_HEAD" ||
            location == "APP_HEAD" ||
            location == "MAGNETIC_HEAD" ||
            location == "CHARGER_HEAD"
          }
          role="button"
          description="Oral-B iO Experience Power"
          actionCall={() => {
            if (isMobile) return;
            if (power === 0)
              world === 0
                ? activateTwinkSound.current.play()
                : activateSound.current.play();
            setPower(power > 0 ? 0 : 1);
          }}
        >
          <mesh
            geometry={nodes.Power_Button.geometry}
            onPointerDown={() => {
              if (!isMobile) return;
              if (power === 0)
                world === 0
                  ? activateTwinkSound.current.play()
                  : activateSound.current.play();
              setPower(power > 0 ? 0 : 1);
            }}
            onPointerOver={() => setHovered(true)}
            onPointerLeave={() => setHovered(false)}
          >
            <ButtonMaterial />
            <mesh geometry={nodes.Power_Button_Light.geometry}>
              <animated.meshStandardMaterial
                color={"red"}
                emissive={a11y.focus ? "orangered" : "white"}
                //not really visible cause happening while not in scene
                emissiveIntensity={spring.dotEmissive}
                envMapIntensity={spring.envMapIntensity}
              />
            </mesh>
          </mesh>
        </A11y>

        <A11y
          disabled={
            location == "BRUSHHEAD_HEAD" ||
            location == "SENSOR_HEAD" ||
            location == "TECHNOLOGY_HEAD" ||
            location == "APP_HEAD" ||
            location == "MAGNETIC_HEAD" ||
            location == "CHARGER_HEAD" ||
            location == "SCENE_INTRO"
          }
          role="button"
          description="Mode"
          actionCall={() => {
            if (isMobile) return;
            setDisplay(order[(order.indexOf(display) + 1) % order.length]);
          }}
        >
          <mesh
            geometry={nodes.Mode_Button.geometry}
            onPointerDown={() => {
              if (!isMobile) return;
              setDisplay(order[(order.indexOf(display) + 1) % order.length]);
            }}
            onPointerOver={() => setHovered(true)}
            onPointerLeave={() => setHovered(false)}
          >
            <ButtonMaterial />
            <mesh geometry={nodes.Mode_Button_Light.geometry}>
              <animated.meshStandardMaterial
                color={a11y.focus ? "orangered" : "white"}
                emissive={a11y.focus ? "orangered" : "white"}
                emissiveIntensity={spring.power}
                envMapIntensity={spring.envMapIntensity}
              />
            </mesh>
          </mesh>
        </A11y>

        <mesh geometry={nodes.Soft_Top.geometry}>
          <HousingMaterial />
        </mesh>

        <mesh geometry={nodes.Window_Hard.geometry}>
          <animated.meshPhysicalMaterial
            color={"black"}
            clearcoat={1.0}
            transmission={0.9}
            transmissionMap={displayTransmissionMap}
            transmissionMap-flipY={false}
            transparent={true}
            envMapIntensity={spring.envMapIntensity}
          />
        </mesh>

        <mesh geometry={nodes.Hard_Mid.geometry}>
          <HousingMaterial />
        </mesh>
      </group>
    </>
  );
}

export default Housing;

useGLTF.preload(model, "./draco/gltf/");

const SCREENS = {
  DAILY_CLEAN_TEETH: [0, 0],
  HELLO_SUN: [0, 1],
  GOOD_MORNING: [0, 2],
  GOOD_NIGHT: [0, 3],
  HELLO_SMILE_1: [0, 4],
  HELLO_SMILE_2: [1, 0],
  LOAD: [1, 1],
  IO: [1, 2],
  TIME: [1, 3],
  RING_LIGHT: [1, 5],
  INTENSE: [2, 0],
  WHITEN: [2, 1],
  SENSITIVE: [2, 2],
  SUPER_SENSITIVE: [2, 3],
  GUM_CARE: [2, 4],
  REFILL_IS_USED_UP: [2, 5],
  SETTINGS_2: [3, 0],
  TONGUE_CLEAN: [3, 1],
};
