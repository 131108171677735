import React, { useEffect } from "react";
import {
  useTranslatedText,
  useControlOptions,
  useBrushhead,
  useBrush,
} from "../hooks";
import { ScrollWrapper } from "../components";
import sensitiveSide from "../../assets/images/scroll_brushhead_sensitive_side.jpg";
import sensitiveFront from "../../assets/images/scroll_brushhead_sensitive_front.jpg";
import ultimateSide from "../../assets/images/scroll_brushhead_ultimate_side.jpg";
import ultimateFront from "../../assets/images/scroll_brushhead_ultimate_front.jpg";
import video1 from "../../assets/videos/FEAT iO BRUSHHEAD.mp4";

function Brushhead() {
  const [, { setBrushRotation, setBrushPosition }] = useBrush();

  const [TEXT] = useTranslatedText();

  const [, { move, enable, lookAt }] = useControlOptions();

  const [brush, setBrushhead, color, setColor] = useBrushhead();

  useEffect(() => {
    setBrushRotation([0, -Math.PI / 6, 0]);

    setBrushPosition([0, 0.75, 0]);

    enable(true);

    move([0, 24, 15]);

    lookAt([0, 20, 0]);
  }, []);

  return (
    <>
      <div
        className="position-absolute"
        style={{ top: "4rem", left: 0, width: "100%" }}
      >
        <div className={"container-xxl"}>
          <div className="row mt-1 mt-lg-2">
            <div className="col" style={{ marginLeft: "4rem" }}>
              <h3 className={"category-headline text-white-50"}>
                {brush === 0 ? TEXT.BRUSHHEAD_ULTIMATE : TEXT.BRUSHHEAD_GENTLE}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div
        className={""}
        style={{ position: "absolute", top: "40%", width: "100%" }}
      >
        <div className="container-xxl">
          <div className="row">
            <div className="col d-flex justify-content-around">
              <div className={"d-flex align-items-center flex-column"}>
                <div className={`hotspot`} onClick={() => setBrushhead(0)}>
                  <div
                    className="hotspot-label position-relative"
                    style={{ transform: "translate(0,0)" }}
                  >
                    <span
                      className={"hotspot-link text-uppercase"}
                      style={{ whiteSpace: "pre" }}
                    >
                      {TEXT.BRUSHHEAD_ULTIMATE?.replace(" ", "\n")}
                    </span>
                  </div>
                </div>

                <div className={"colorpicker d-flex mt-3"}>
                  <button
                    className={`btn btn-transparent colorpicker-button d-flex justify-content-center flex-column align-items-center ${
                      color === 0 && brush === 0 ? "selected" : ""
                    }`}
                    onClick={() => {
                      setColor(0);
                      setBrushhead(0);
                    }}
                  >
                    <span
                      className={"colorpicker-pill mb-2"}
                      style={{ background: "#222222" }}
                    >
                      {" "}
                    </span>
                    <span className={"colorpicker-text"}>{TEXT.BLACK}</span>
                  </button>

                  <button
                    className={`btn btn-transparent colorpicker-button d-flex justify-content-center flex-column align-items-center ${
                      color === 1 && brush === 0 ? "selected" : ""
                    }`}
                    onClick={() => {
                      setColor(1);
                      setBrushhead(0);
                    }}
                  >
                    <span
                      className={"colorpicker-pill mb-2"}
                      style={{ background: "#ffffff" }}
                    >
                      {" "}
                    </span>
                    <span className={"colorpicker-text"}>{TEXT.WHITE}</span>
                  </button>
                </div>
              </div>

              <div className={"d-flex align-items-center flex-column"}>
                <div className={`hotspot`} onClick={() => setBrushhead(1)}>
                  <div
                    className="hotspot-label position-relative"
                    style={{ transform: "translate(0,0)" }}
                  >
                    <span
                      className={"hotspot-link text-uppercase"}
                      style={{ whiteSpace: "pre" }}
                    >
                      {TEXT.BRUSHHEAD_GENTLE?.replace(" ", "\n")}
                    </span>
                  </div>
                </div>

                <div className={"colorpicker d-flex mt-3 w-auto"}>
                  <button
                    className={`btn btn-transparent colorpicker-button d-flex justify-content-center flex-column align-items-center ${
                      brush === 1 ? "selected" : ""
                    }`}
                    onClick={() => {
                      setColor(1);
                      setBrushhead(1);
                    }}
                  >
                    <span
                      className={"colorpicker-pill mb-2"}
                      style={{ background: "#ffffff" }}
                    >
                      {" "}
                    </span>
                    <span className={"colorpicker-text"}>{TEXT.WHITE}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ScrollWrapper
        redirect={"SCENE_TECHNOLOGY"}
        headline={TEXT.BRUSHHEAD_HEAD}
      >
        <div className={"row mb-5"}>
          <div className="col">
            <video width="1920" height="1080" controls src={video1}>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col">{TEXT.BRUSHHEAD_DESC_1}</div>
        </div>

        <div className={"row mb-5"}>
          <div className="col">
            <img src={ultimateSide} alt="" />
          </div>
          <div className="col">
            <img src={ultimateFront} alt="" />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col">{TEXT.BRUSHHEAD_DESC_2}</div>
        </div>

        <div className={"row mb-5"}>
          <div className="col">
            <img src={sensitiveSide} alt="" />
          </div>
          <div className="col">
            <img src={sensitiveFront} alt="" />
          </div>
        </div>
      </ScrollWrapper>
    </>
  );
}

export default Brushhead;
