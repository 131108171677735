import create from 'zustand';

const useStore = create(set => {
    return {
        brush: 0,
        setBrush: (value) => set((state) => ({brush: value})),
        color: 0,
        setColor: (value) => set((state) => ({color: value})),
    }
})

const useBrushhead = () => {

    return [
        useStore(state => state.brush),
        useStore(state => state.setBrush),
        useStore(state => state.color),
        useStore(state => state.setColor),
    ];

};

export default useBrushhead;