import React, { Suspense, useMemo } from "react";
import { Canvas } from "@react-three/fiber";
import { A11yAnnouncer, A11y, A11ySection } from "@react-three/a11y";
import { Environment } from "@react-three/drei/core/Environment";
import { Preload } from "@react-three/drei/core/Preload";
import { Router, Switch, Route, Link } from "wouter";

import { Brush, Phone, GlowingRing, Charger9, Charger10 } from "./models";
import {
  Dolly,
  LanguageSwitch,
  Tutorial,
  ScrollWrapper,
  OpenTutorial,
  ColorPicker,
} from "./components";
import { Intro, SensorScene, LoadingScreen } from "./pages";
import * as Technology from "./pages";

import {
  useHashLocation,
  useTranslatedText,
  useUrlParams,
  useControlOptions,
} from "./hooks";
import logo from "../assets/images/logo.png";
import "../styles/App.scss";
import hdr from "../assets/images/studio_05.hdr";
import { ResizeObserver } from "@juggle/resize-observer";
import { a, useSpring } from "@react-spring/web";
import LanguageLoader from "./components/LanguageLoader";

function App() {
  const [{ measure }] = useControlOptions();

  const [location] = useHashLocation();

  const [TEXT] = useTranslatedText();

  const params = useUrlParams();

  const embedded = useMemo(() => {
    if (params.embed === "false") return false;
    else return true;
  }, [params.embed]);

  const { opacity } = useSpring({
    opacity: measure ? 0 : 1,
  });

  const [{ tutorialOpen }] = useControlOptions();

  return (
    <>
      {tutorialOpen && <Tutorial />}

      <Canvas
        className={"canvas"}
        style={{
          border: "0px solid red",

          maxWidth: "50rem",
          left: "50%",
          transform: "translate(-50%,0)",
        }}
        pixelratio={window.devicePixelRatio}
        resize={{ polyfill: ResizeObserver }}
        camera={{ fov: 45 }}
      >
        <A11ySection>
          <Preload all />

          <color attach={"alpha"} args={[true]} />

          <Dolly />

          <Suspense fallback={null}>
            <Environment files={hdr} background={false} />
          </Suspense>

          <Suspense fallback={null}>
            {params.series === "10" ? <Charger10 /> : <Charger9 />}
          </Suspense>

          <Brush />

          {(location === "SCENE_INTRO" || location === "/") && (
            <Suspense fallback={null}>
              <LoadingScreen />
            </Suspense>
          )}

          <Router hook={useHashLocation}>
            <Switch>
              <Route path="SCENE_INTRO">
                <Intro />
              </Route>

              <Route path="SCENE_TECHNOLOGY">
                <Technology.Index />
              </Route>
            </Switch>

            <Route path={"MEASUREMENTS"}>
              <Technology.Measurements />
            </Route>

            <Route path={"SENSOR_HEAD"}>
              <SensorScene />
            </Route>

            <Route path={"DISPLAY_HEAD"}>
              <Suspense fallback={null}>
                <GlowingRing
                  position={[0, 9.36, 1.33]}
                  rotationY={-0.08}
                  progress={100}
                />
              </Suspense>
            </Route>

            <Route path="APP_HEAD">
              <Suspense fallback={null}>
                <Phone
                  position={[-6, 1, 6]}
                  scale={[1.6, 1.6, 1.6]}
                  rotation={[0, 0.3, 0.1]}
                />
              </Suspense>
            </Route>
          </Router>
        </A11ySection>
      </Canvas>

      <A11yAnnouncer />

      {!params.embed && (
        <div
          className={"my-3"}
          style={{
            position: "absolute",
            top: "0rem",
            width: "100%",
            pointerEvents: "none",
          }}
        >
          <div className="container">
            <div className="row">
              <div
                className="col d-flex justify-content-end align-items-center"
                style={{ height: "4rem" }}
              >
                <a
                  href="//oralb.com"
                  style={{ pointerEvents: "all" }}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img
                    src={logo}
                    style={{ width: "8rem" }}
                    className={"my-2"}
                    alt={"Logo OralB iO"}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <Router hook={useHashLocation}>
        <Switch>
          <Route path={"SCENE_TECHNOLOGY"}>
            <OpenTutorial delay={2000} />

            {!params.embed && (
              <div className="container" style={{ height: "100%" }}>
                <div className="row">
                  <div
                    className="col my-3 d-flex align-items-center"
                    style={{ height: "4rem" }}
                  >
                    <h3
                      className={"category-headline text-white"}
                      style={{ marginBottom: "-.15em" }}
                    >
                      {TEXT.SERIES_9}
                    </h3>
                  </div>
                </div>
              </div>
            )}

            <div className="container position-relative">
              <div className="row mt-4">
                <div className="col d-flex justify-content-end">
                  <div className="hotspot">
                    <div
                      className="hotspot-label"
                      style={{
                        transform: "translate(0, 0)",
                        position: "relative",
                      }}
                    >
                      <Router hook={useHashLocation}>
                        <Link
                          to={"MEASUREMENTS"}
                          className={"hotspot-link text-uppercase"}
                        >
                          {TEXT.SHOW_MEASURE}
                        </Link>
                      </Router>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a.div
              style={{
                height: "100%",
                width: "100%",
                position: "fixed",
                top: "50%",
                right: "50%",
                transform: "translate(50%, -50%)",
                pointerEvents: "none",
                opacity,
              }}
            >
              <div
                className="container d-flex flex-column"
                style={{ height: "100%" }}
              >
                <div
                  className="row flex-column mt-auto my-lg-auto"
                  style={{ width: "100%" }}
                >
                  <div className="col d-flex justify-content-center justify-content-lg-end ">
                    <ColorPicker />
                  </div>
                </div>
                <div style={{ width: "100%", height: "2.5rem" }}></div>
              </div>
            </a.div>
          </Route>

          <Route path={"MEASUREMENTS"}>
            <ScrollWrapper
              redirect={"SCENE_TECHNOLOGY"}
              headline={TEXT.SHOW_MEASURE}
            />
          </Route>

          <Route path={"BRUSHHEAD_HEAD"}>
            <Technology.Brushhead />
          </Route>

          <Route path={"TECHNOLOGY_HEAD"}>
            <Technology.IOTechnology />
          </Route>

          <Route path={"SENSOR_HEAD"}>
            <Technology.Sensor />
          </Route>

          <Route path={"DISPLAY_HEAD"}>
            <Technology.Display />
          </Route>

          <Route path={"MAGNETIC_HEAD"}>
            <Technology.MagneticDrive />
          </Route>

          <Route path={"APP_HEAD"}>
            <Technology.OralbApp />
          </Route>

          <Route path={"CHARGER_HEAD"}>
            <Technology.Charger />
          </Route>
        </Switch>
      </Router>

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100dvw",
          height: "100dvh",
          pointerEvents: "none",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          // className="container-xxl"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            margin: 0,
            padding: 0,
            maxWidth: "1236px",
          }}
        >
          <div
            // className="row flex-column"
            style={{
              position: "absolute",
              width: "100%",
              bottom: 0,
              left: 0,
            }}
          >
            {!embedded && (
              <div
              // className="col-12 align-self-end d-flex flex-column"
              >
                <div
                  style={{
                    position: "absolute",
                    right: "2rem",
                    bottom: "3rem",
                  }}
                >
                  <LanguageSwitch />
                </div>

                <div
                // className="row"
                >
                  <div
                  // className="col-sm-12"
                  >
                    <div
                      className="d-flex justify-content-evenly pb-3"
                      style={{ pointerEvents: "all" }}
                    >
                      <div style={{ width: 0, height: "2rem" }}>
                        <a
                          href={TEXT.LEGALS_IMPRINT_LINK}
                          target={"_blank"}
                          rel="noreferrer"
                          className={"legals"}
                          style={{
                            position: "absolute",
                            transform: "translateX(-50%)",
                          }}
                        >
                          {TEXT.LEGALS_IMPRINT}
                        </a>
                      </div>

                      <div style={{ width: 0, height: "2rem" }}>
                        <a
                          href={TEXT.LEGALS_PRIVACY_LINK}
                          target={"_blank"}
                          rel="noreferrer"
                          className={"legals"}
                          style={{
                            position: "absolute",
                            transform: "translateX(-50%)",
                          }}
                        >
                          {TEXT.LEGALS_PRIVACY}
                        </a>
                      </div>
                      <div style={{ width: 0, height: "2rem" }}>
                        <a
                          href={TEXT.LEGALS_TERMS_LINK}
                          target={"_blank"}
                          rel="noreferrer"
                          className={"legals"}
                          style={{
                            position: "absolute",
                            transform: "translateX(-50%)",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {TEXT.LEGALS_TERMS}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <LanguageLoader />
    </>
  );
}

export default App;
