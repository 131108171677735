import React, { useEffect } from "react";
import { Hotspot } from "../components";
import {
  useTranslatedText,
  useControlOptions,
  useButtons,
  useBrush,
  useSeries,
} from "../hooks";
import { useGLTF } from "@react-three/drei/core/useGLTF";
import inner from "../../assets/models/inner.glb";
import { isMobile } from "react-device-detect";

export { default as Brushhead } from "./Brushhead.jsx";
export { default as IOTechnology } from "./IOTechnology.jsx";
export { default as Intro } from "./Intro.jsx";
export { default as LoadingScreen } from "./LoadingScreen.jsx";
export { default as SensorScene } from "./SensorScene.jsx";
export { default as Sensor } from "./Sensor.jsx";
export { default as Display } from "./Display.jsx";
export { default as MagneticDrive } from "./MagneticDrive.jsx";
export { default as OralbApp } from "./OralbApp.jsx";
export { default as Charger } from "./Charger.jsx";
export { default as Measurements } from "./Measurements";

function Index() {
  const [{ video }, { setBrushRotation, setBrushPosition }] = useBrush();

  const series = useSeries();

  const [TEXT] = useTranslatedText();

  const [, { enable, move, lookAt }] = useControlOptions();

  const [, setPower, , setWorld] = useButtons();

  useEffect(() => {
    move(isMobile ? [0, 10, 44] : [0, 10, 36]);

    lookAt([0, 10.5, 0]);

    setBrushRotation([0, 0, 0]);

    setBrushPosition([0, 0, 0]);

    enable(true);

    useGLTF.preload(inner, "./draco/gltf/");

    setPower(1);

    setWorld(1);
  }, []);

  return (
    <>
      <Hotspot
        text={TEXT.BRUSHHEAD_HEAD}
        uri={"BRUSHHEAD_HEAD"}
        position={[-0.5, 20, 1]}
        direction={"left"}
      />
      <Hotspot
        text={TEXT.SENSOR_HEAD}
        uri={"SENSOR_HEAD"}
        position={[0.75, 17, 1]}
        direction={"right"}
      />
      <Hotspot
        text={TEXT.TECHNOLOGY_HEAD}
        uri={"TECHNOLOGY_HEAD"}
        position={[1.25, 14, 0]}
        direction={"right"}
      />
      <Hotspot
        text={TEXT.DISPLAY_HEAD}
        uri={"DISPLAY_HEAD"}
        position={[-1, 12, 1]}
        direction={"left"}
      />
      <Hotspot
        text={TEXT.APP_HEAD}
        uri={"APP_HEAD"}
        position={[-2, 8, 1]}
        direction={"left"}
        onClick={() => video.play()}
      />
      <Hotspot
        text={TEXT.MAGNETIC_HEAD}
        uri={"MAGNETIC_HEAD"}
        position={[1, 6, 1]}
        direction={"right"}
      />

      <Hotspot
        text={TEXT[series.charger.head_key]}
        uri={"CHARGER_HEAD"}
        position={[-2, 1, 1]}
        direction={"left"}
      />
    </>
  );
}

export { Index };
