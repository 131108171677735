import create from 'zustand';

const useStore = create(set => ({
    index: 'HELLO_SMILE_2',
    setIndex: (value) => set(() => ({index: value})),
}))

const useDisplay = () => {

    return [
        useStore(state => state.index),
        useStore(state => state.setIndex),
    ];

};

export default useDisplay;
