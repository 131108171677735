/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react';
import {TextureLoader, RepeatWrapping} from 'three';
import { useGLTF } from '@react-three/drei/core/useGLTF'
import { useFrame, useLoader } from "@react-three/fiber";
import magnet_diffuse from '../../assets/images/magnet_diffuse.png';
import magnet_emissive from '../../assets/images/magnet_emissive.png';
import model from '../../assets/models/inner.glb';

function Model(props) {

    const group = useRef();

    const ref1 = useRef();

    const ref2 = useRef();

    const ref3 = useRef();

    const { nodes, materials } = useGLTF(model,"./draco/gltf/");

    const [
        magnet_diffuse_map,
        magnet_emissive_map,
    ] = useLoader(TextureLoader, [
        magnet_diffuse,
        magnet_emissive
    ])

    magnet_diffuse_map.wrapT = RepeatWrapping;
    magnet_diffuse_map.repeat.x = 1.2;
    magnet_emissive_map.wrapT = RepeatWrapping;
    magnet_emissive_map.repeat.x = 1.2;

    useFrame(({clock}) => {
        ref1.current.position.z = Math.sin(clock.elapsedTime*25 + Math.PI)*0.2;
        ref2.current.position.z = Math.sin(clock.elapsedTime*25)*0.2;
        ref3.current.position.z = Math.sin(clock.elapsedTime*25)*0.2 - 15.64;
        magnet_diffuse_map.offset.y -= 0.05;
        magnet_emissive_map.offset.y -= 0.05;
    });

    return (
        <group ref={group} {...props} dispose={null}>
            <group rotation={[Math.PI / 2, 0, 0]}>
                <mesh
                    geometry={nodes.ME_OralBiO_C_Housing_SoftTop_LOD1_002.geometry}
                    material={materials['MAT_Brush_iO_Outer_Plastic_LightGey_Glossy.003']}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Board_DisplayBack_LOD2_001.geometry}
                    material={materials['MAT_Brush_iO_Inner_Rest.002']}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Changecoil_Frame_LOD2_001.geometry}
                    material={materials['MAT_Brush_iO_Inner_Plastic_LightGey_Glossy.002']}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Changecoil_Wire_LOD2_001.geometry}
                    material={nodes.ME_OralBiO_C_Changecoil_Wire_LOD2_001.material}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Drive_Battery_LOD2_001.geometry}
                    material={materials['MAT_Brush_iO_Inner_Batterie.002']}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Drive_BatteryClip_LOD2_001.geometry}
                    material={nodes.ME_OralBiO_C_Drive_BatteryClip_LOD2_001.material}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Drive_Frame_LOD2_001.geometry}
                    material={materials['MAT_Brush_iO_Inner_Plastic_Black-Glossy.002']}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Drive_FrameFront_LOD2_001.geometry}
                    material={materials['MAT_Brush_iO_Inner_Plastic_Black-Blurry.002']}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Drive_FrameRing_LOD2_001.geometry}
                    material={materials['MAT_Brush_iO_Inner_Glass.002']}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Drive_BatterySpring_LOD2_001.geometry}
                    material={nodes.ME_OralBiO_C_Drive_BatterySpring_LOD2_001.material}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Board_Main_LOD2_001.geometry}
                    material={materials['MAT_Brush_iO_Inner_Platine.002']}
                />
                <group position={[0, -0.41, -15.64]} ref={ref3}>
                    <mesh
                        geometry={nodes.ME_OralBiO_C_Drive_Rubber_LOD2_002.geometry}
                        material={materials['MAT_Brush_iO_Outer_Rubber_Grey.002']}
                    />
                    <mesh
                        geometry={nodes.ME_OralBiO_C_Connector1_Connector_LOD2_.geometry}
                        material={materials['MAT_Brush_iO_Outer_Plastic_LightGey_Glossy.004']}
                        position={[0, 0.41, 15.64]}
                        rotation={[-Math.PI / 2, 0, 0]}
                    />
                    <mesh
                        geometry={nodes.ME_OralBiO_C_Connector1_Cylinder_LOD2_001.geometry}
                        material={nodes.ME_OralBiO_C_Connector1_Cylinder_LOD2_001.material}
                    />
                    <mesh
                        geometry={nodes.ME_OralBiO_C_Connector1_Pushrod_LOD2_001.geometry}
                        material={nodes.ME_OralBiO_C_Connector1_Pushrod_LOD2_001.material}
                    />
                    <mesh
                        geometry={nodes.ME_OralBiO_C_Drive_AttachmentTop_LOD2_001.geometry}
                        material={materials['MAT_Brush_iO_Outer_Metal_Dark.002']}
                    />
                </group>
                <mesh
                    geometry={nodes.ME_OralBiO_C_Actuator_Magnet_LOD2_001.geometry}
                    material={nodes.ME_OralBiO_C_Actuator_Magnet_LOD2_001.material}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Drive_Rod_LOD2_001.geometry}
                    material={nodes.ME_OralBiO_C_Drive_Rod_LOD2_001.material}
                />
                <mesh
                    geometry={nodes.ME_OralBiO_C_Actuator_MagnetBack_LOD2_001.geometry}
                    //material={nodes.ME_OralBiO_C_Actuator_MagnetBack_LOD2_001.material}
                    ref={ref2}
                >
                    <meshStandardMaterial
                        //color={0x000000}
                        map={magnet_diffuse_map}
                        emissive={0xe6007e}
                        emissiveMap={magnet_emissive_map}
                        //emissiveMap-repeat={[1,0.1]}
                        emissiveIntensity={0.1}
                        roughness={0.2}
                        //map-offset={spring.offset}
                    />
                </mesh>
                <mesh
                    geometry={nodes.OralBiO_C_Resonator_Mass2_LOD2_001.geometry}
                    //material={materials['MAT_Brush_iO_Inner_Metal_plates.002']}
                    ref={ref1}
                >
                    <meshStandardMaterial
                        //color={0x000000}
                        map={magnet_diffuse_map}
                        //map-repeat={[1,3]}
                        emissive={0xe6007e}
                        emissiveMap={magnet_emissive_map}
                        emissiveIntensity={0.1}
                        roughness={0.2}
                        //map-offset={spring.offset}
                    />
                </mesh>
                <mesh
                    geometry={nodes.OralBiO_C_Resonator_Shaft_LOD2_001.geometry}
                    material={materials['MAT_Brush_iO_Inner_Metal2.002']}

                />
            </group>
        </group>
    )
}

export default Model
