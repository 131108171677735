import touchMove from "../../assets/images/Touch_Move.png";
import touchPinch from "../../assets/images/Touch_Pinch.png";
import mousePinch from "../../assets/images/Mouse_Move.png";
import mouseMove from "../../assets/images/Mouse_Zoom.png";
import {animated, useSpring} from '@react-spring/web'
import {useControlOptions, useTranslatedText} from "../hooks";
import { useEffect, useRef } from "react";


const Tutorial = () => {

 const [{ tutorialOpen }, { makeTutorialDirty, toggleTutorial }] =
   useControlOptions();
 const [TEXT] = useTranslatedText();

 const { opacity, transform } = useSpring({
   transform: tutorialOpen
     ? "translate(-50%,0%) scale(100%,100%)"
     : "translate(-50%,100%) scale(90%,90%)",
   opacity: tutorialOpen ? 1 : 0,
 });

 const closeButtonRef = useRef(null);

   useEffect(() => {
     if (tutorialOpen && closeButtonRef.current) {
       closeButtonRef.current.focus();
     }

     const handleKeyDown = (event) => {
       if (event.key === "Tab") {
         // Verhindere das normale Tab-Verhalten
         event.preventDefault();

         // Setze den Fokus zurück auf den Schließen-Button
         closeButtonRef.current.focus();
       }
     };

     if (tutorialOpen) {
       document.addEventListener("keydown", handleKeyDown);
       return () => {
         document.removeEventListener("keydown", handleKeyDown);
       };
     }
   }, [tutorialOpen]);

 const handleToggle = () => {
   makeTutorialDirty();
   toggleTutorial(false);
 };

 const handleKeyDown = (event) => {
   if (event.key === "Enter") {
     handleToggle();
   }
 };

    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          overflow: "hidden",
          pointerEvents: "none",
          zIndex: 1,
          opacity,
        }}
      >
        <animated.div
          className={"p-2 p-lg-0 pb-5 pb-lg-5"}
          style={{
            position: "absolute",
            bottom: "-6rem",
            width: "100%",
            left: "50%",
            maxWidth: "32rem",
            transform,
            opacity,
          }}
        >
          <div
            className="container py-4"
            style={{
              borderRadius: "1.4rem",
              background: "white",
            }}
          >
            <button
              ref={closeButtonRef}
              className={"btn btn-link p-0 ms-auto"}
              onClick={handleToggle}
              onKeyDown={handleKeyDown}
              tabIndex={0}
              type="button"
              aria-label="Close"
              style={{
                position: "absolute",
                top: "1.5rem",
                right: "1.5rem",
                pointerEvents: "auto",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="46.165"
                height="46.165"
                viewBox="0 0 46.165 46.165"
                className={"img-fluid"}
                style={{ width: "1.5rem" }}
              >
                <path
                  id="Vereinigungsmenge_1"
                  data-name="Vereinigungsmenge 1"
                  d="M23.083,27.678,4.6,46.165,0,41.569,18.487,23.083,0,4.6,4.6,0,23.083,18.487,41.569,0l4.6,4.6L27.678,23.083,46.165,41.569l-4.6,4.6Z"
                  fill="black"
                />
              </svg>
            </button>

            <div className="row mt-3 mb-5">
              <div className="col">
                <div className="row mb-4">
                  <div className="col d-flex justify-content-center">
                    <img
                      src={touchMove}
                      className={"d-touch"}
                      alt=""
                      style={{ maxHeight: "8rem", width: "auto" }}
                    />
                    <img
                      src={mouseMove}
                      className={"d-pointer"}
                      alt=""
                      style={{ maxHeight: "8rem", width: "auto" }}
                    />
                  </div>
                </div>
                <p className={"d-touch"}>{TEXT.MOVE_TOUCH_DESC}</p>
                <p className={"d-pointer"}>{TEXT.MOVE_MOUSE_DESC}</p>
              </div>
              <div className="col">
                <div className="row mb-4">
                  <div className="col d-flex justify-content-center">
                    <img
                      src={touchPinch}
                      className={"d-touch"}
                      alt=""
                      style={{ maxHeight: "8rem", width: "auto" }}
                    />
                    <img
                      src={mousePinch}
                      className={"d-pointer"}
                      alt=""
                      style={{ maxHeight: "8rem", width: "auto" }}
                    />
                  </div>
                </div>
                <p className={"d-touch"}>{TEXT.ZOOM_TOUCH_DESC}</p>
                <p className={"d-pointer"}>{TEXT.ZOOM_MOUSE_DESC}</p>
              </div>
            </div>
          </div>
        </animated.div>
      </div>
    );

}

export default Tutorial
