import React, { useEffect } from "react";
import {
  useControlOptions,
  useButtons,
  useTranslatedText,
  useUrlParams,
  useHashLocation,
} from "../hooks";
import { useProgress } from "@react-three/drei/core/useProgress";
import { GlowingRing } from "../models";
import { config, useSpring } from "react-spring";
import { Html } from "@react-three/drei/web/Html";
import { langs } from "../components/LanguageSwitch.jsx";

const LoadingScreen = () => {
  const [power] = useButtons();

  const { progress, loaded, total } = useProgress();

  const [, { enable, move, lookAt }] = useControlOptions();

  const [, navigate] = useHashLocation();

  const { percentage } = useSpring({
    percentage: progress,
    config: config.slow,
  });

  useEffect(() => {
    enable(false);

    lookAt([0, 13, 0]);

    move([0, 13, 10]);
  }, []);

  useEffect(() => {
    if (loaded === total) navigate("SCENE_INTRO");
  }, [loaded, total, navigate]);

  return (
    <>
      <GlowingRing position={[0, 13, 1.25]} progress={progress} power={power} />
      {loaded < total && (
        <Html
          center
          position={[0, 12.9, 2.0]}
          className={"content-text"}
          style={{ width: "20rem", textAlign: "center" }}
        >
          <p>{Math.floor(percentage.get() * 100) / 100}%</p>
        </Html>
      )}
    </>
  );
};

export default LoadingScreen;
