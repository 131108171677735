export default () => {

    return (
        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231.49 471.01" style={{transform: 'scale(4.8) translate(0, -50%)', opacity: 0.66}}>
            <path d="M142.92,18.78c0-.81-.27-1.61-.77-2.25-.49-.64-1.21-1.11-1.99-1.3-2.32-.59-4.58,1.18-4.56,3.58,.02,2.37,2.26,4.1,4.56,3.52,.78-.2,1.5-.66,1.99-1.3,.5-.64,.78-1.44,.77-2.25Z"
                  fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path d="M115.71,20.95h-10.47c-.18,0-.34-.02-.5-.06-.61-.14-1.12-.55-1.4-1.07-.8-1.46,.27-3.21,1.9-3.21,5.22,0,15.72,0,20.94,0,.25,0,.48,.04,.7,.11,1.29,.43,1.85,1.94,1.2,3.1-.3,.55-.83,.93-1.4,1.07-.15,.04-.31,.05-.45,.06-.01,0-10.49,0-10.52,0Z"
                  fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <path d="M171.1,18.25c.01,3.94-2.03,7.7-5.32,9.84-1.02,.67-2.16,1.18-3.34,1.5-.67,.18-1.35,.31-2.04,.37-.26,.03-.54,.03-.8,.04-.35-.21-87.27,.16-87.65,0-.3,0-.63-.02-.93-.05-1.86-.17-3.68-.79-5.25-1.79-1.66-1.05-3.05-2.54-4-4.26-.91-1.65-1.41-3.52-1.45-5.4v-2.98c-.01-.21-.04-.42-.1-.63-.18-.67-.68-1.28-1.27-1.59-.38-.21-.82-.32-1.26-.32-1.21,.07-22.57-.14-23.36,.12-1.88,.19-3.75,.61-5.53,1.24-5.56,1.95-10.32,6.04-13.07,11.25-1.01,1.9-1.77,3.94-2.23,6.04-.32,1.43-.5,2.9-.55,4.37-.28,1.47,.16,396.94,0,398.54,.02,.61,.05,1.28,.12,1.88,.88,8.88,6.8,16.65,15.2,19.83,1.6,.61,3.29,1.05,4.98,1.3,.71,.09,1.46,.19,2.18,.22,.34,0,.73,.04,1.07,.04,.22,.12,157.95-.08,158.2,0,.52-.01,1.08,0,1.6-.05l.27-.02c2.06-.16,4.12-.6,6.07-1.29,2.07-.73,4.06-1.76,5.85-3.03,5.95-4.2,9.7-11.08,10-18.35,.43-.03-.26-397.9,.02-398.28,0-.61-.02-1.26-.07-1.87-.13-1.68-.45-3.35-.93-4.96-.71-2.38-1.81-4.66-3.22-6.71-1.8-2.61-4.14-4.86-6.82-6.56-3.34-2.13-7.22-3.4-11.17-3.66-.33-.03-.73-.04-1.07-.05h-21.48s-.1,0-.14,0c-.33,.02-.66,.1-.96,.24-.74,.34-1.26,1-1.45,1.76-.04,.16-.06,.33-.07,.49,0,.04,0,.1,0,.14,0,0,0,2.62,0,2.62Z"
                  fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.75"/>
            <path d="M37.85,469.26c-12.22,.03-23.8-6.3-30.37-16.61-2.12-3.31-3.71-7-4.65-10.82-.64-2.61-1-5.32-1.04-8.01-.11-1.41,.06-395.16,0-396.61l.02-.62,.03-.62,.04-.62c.19-2.67,.68-5.38,1.46-7.94C6.32,17.39,13.75,8.99,23.33,4.82c3.41-1.5,7.08-2.47,10.79-2.86,1.21-.11,2.51-.2,3.73-.19,.65,.08,155.75-.07,156.34,0l.62,.02,.62,.03,.62,.04,.62,.05c3.5,.31,6.99,1.13,10.26,2.43,6.75,2.68,12.65,7.47,16.66,13.52,2.29,3.45,4,7.32,4.99,11.34,.39,1.59,.69,3.27,.86,4.9,.1,1.01,.18,2.1,.19,3.11,.32,.14-.22,395.68,0,395.99v.62l-.02,.62-.03,.62c-.57,11.53-6.82,22.29-16.57,28.5-2.79,1.78-5.85,3.2-9.02,4.15-1.97,.59-4.04,1.03-6.08,1.28-.77,.12-1.7,.16-2.48,.22-.56,.02-1.31,.05-1.87,.05H37.85Z"
                  fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.75"/>
            <path d="M37.85,469.26c-12.22,.03-23.8-6.3-30.37-16.61-2.12-3.31-3.71-7-4.65-10.82-.64-2.61-1-5.32-1.04-8.01-.11-1.41,.06-395.16,0-396.61l.02-.62,.03-.62,.04-.62c.19-2.67,.68-5.38,1.46-7.94C6.32,17.39,13.75,8.99,23.33,4.82c3.41-1.5,7.08-2.47,10.79-2.86,1.21-.11,2.51-.2,3.73-.19,.65,.08,155.75-.07,156.34,0l.62,.02,.62,.03,.62,.04,.62,.05c3.5,.31,6.99,1.13,10.26,2.43,6.75,2.68,12.65,7.47,16.66,13.52,2.29,3.45,4,7.32,4.99,11.34,.39,1.59,.69,3.27,.86,4.9,.1,1.01,.18,2.1,.19,3.11,.32,.14-.22,395.68,0,395.99v.62l-.02,.62-.03,.62c-.57,11.53-6.82,22.29-16.57,28.5-2.79,1.78-5.85,3.2-9.02,4.15-1.97,.59-4.04,1.03-6.08,1.28-.77,.12-1.7,.16-2.48,.22-.56,.02-1.31,.05-1.87,.05H37.85Z"
                  fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.5"/>
        </svg>
    )
}
