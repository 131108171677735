import { useEffect } from "react";
import { useStoreLang } from "../hooks/use-translated-text";

const LanguageLoader = ({ ...props }) => {
  const setText = useStoreLang((state) => state.setText);
  const lang = useStoreLang((state) => state.language);

  
  useEffect(() => {
    console.log(lang);
    import(`../../assets/lang/${lang}.jsx`).then((file) => {
      setText(file.default);
    });
  }, [lang]);

  return null;
};

export default LanguageLoader;
