export default {
  SERIES_9: "Series 9",
  BLACK: "Black",
  WHITE: "White",
  CLOSE: "Close",
  MORE_INFO: "More Info",
  PRESS_POWER: "PRESS THE POWER BUTTON",
  SLOW: "SLOW",
  FAST: "FAST",
  SHOW_MEASURE: "MEASUREMENTS",
  MATERIAL_BLACK: "Black Onyx",
  MATERIAL_WHITE: "White Alabaster",
  MATERIAL_PINK: "Rose Quartz",

  LEGALS_IMPRINT: "Contact",
  LEGALS_IMPRINT_LINK: "https://www.pg.co.uk/contact-us/",
  LEGALS_PRIVACY: "Privacy",
  LEGALS_PRIVACY_LINK: "https://privacypolicy.pg.com/en/",
  LEGALS_TERMS: "Terms & Conditions",
  LEGALS_TERMS_LINK: "https://termsandconditions.pg.com/en-uk/#use",

  BRUSHHEAD_HEAD: "BRUSH-HEADS",
  BRUSHHEAD_DESC_1: (
    <>
      <p>Oral-B iO™ comes with two different brush heads:</p>
      <p>
        Ultimative Clean: 16 degree angle and the tuft-in-tuft arrangement
        ensure effective interdental reach and thorough surface cleaning
      </p>
    </>
  ),
  BRUSHHEAD_DESC_2: (
    <>
      <p>
        Gentle Clean: dense bristle-field, pleasant padding effect thanks to
        fine, long bristles on the outer edge; gently adapts to teeth and
        gumline
      </p>
      <p>
        In addition, Oral-B iO™ reminds you to change your brush head regularly.
      </p>
    </>
  ),
  BRUSHHEAD_ULTIMATE: "Ultimate Clean",
  BRUSHHEAD_GENTLE: "Gentle Care",

  SENSOR_HEAD: "SMART PRESSURE SENSOR",
  SENSOR_DESC: (
    <>
      <p>Smart pressure sensor</p>
      <p>
        The Oral-B iO™ smart pressure sensor light provides positive
        reinforcement and protects gums by:
      </p>
      <p>
        Turning <span style={{ color: "green", fontWeight: 600 }}>green</span>{" "}
        when optimal pressure is applied and{" "}
        <span style={{ color: "red", fontWeight: 600 }}>red</span> when pressure
        is too hard
      </p>
    </>
  ),
  SENSOR_SOFT: "Too Soft",
  SENSOR_HARD: "Too Strong",
  SENSOR_PERFECTION: "Optimal",

  TECHNOLOGY_HEAD: "iO TECHNOLOGY",
  TECHNOLOGY_DESC:
    "Innovative iO Technology \n\nOral-B iO™ technology combines the iconic round brush head with gentle microvibrations for a sensational clean and a gentle brushing experience you can´t resist!",

  DISPLAY_HEAD: "INTERACTIVE DISPLAY",
  DISPLAY_DESC: (
    <>
      <p>
        The interactive display on the handle allows personalization, coaching
        and feedback for improved cleaning results.
      </p>
      <p>Displays:</p>
      <ul>
        <li>Personal greeting with a friendly face</li>
        <li>Color selection of the SmartRings</li>
        <li>Language</li>
        <li>Selection from up to 7 cleaning modes</li>
        <li>Timer to maintain the recommended cleaning time of 2min</li>
        <li>Battery status</li>
        <li>Reminder to change brush head</li>
      </ul>
    </>
  ),
  DISPLAY_MODE_0: "Personal greeting with a friendly face",
  DISPLAY_MODE_0_1: "Personal greeting",
  DISPLAY_MODE_1: "Color selection of the SmartRings",
  DISPLAY_MODE_2: "Settings",
  DISPLAY_MODE_3: "selection from up to 7 cleaning modes",
  DISPLAY_MODE_3_1: "Standard mode for daily cleaning",
  DISPLAY_MODE_3_2:
    "For an extraordinary clean feeling (operated with a higher speed)",
  DISPLAY_MODE_3_3: "Polishing for occasional or everyday use",
  DISPLAY_MODE_3_4: "Gentle, yet thorough cleaning for sensitive areas",
  DISPLAY_MODE_3_5: "Super gentle cleaning for extra sensitive areas",
  DISPLAY_MODE_3_6: "Gentle massage of gums",
  DISPLAY_MODE_3_7: "Tongue cleaning for occasional or everyday use",
  DISPLAY_MODE_4: "timer to maintain the recommended cleaning time of 2min",
  DISPLAY_MODE_5: "battery status",
  DISPLAY_MODE_6: "reminder to change brush head",
  DISPLAY_MODE_PROMPT: "Click to change mode",

  APP_HEAD: "Oral‑B APP",
  APP_DESC: (
    <>
      <p>EXPAND YOUR EXPERIENCE WITH THE ORAL-B APP</p>
      <p>
        The Oral-B app recognises the Oral-B iO™ via Bluetooth and supports the
        cleaning result with various functions:
      </p>
      <ul>
        <li>
          Visual timer indicates each brushing session duration, so users know
          when they have brushed for the dentist-recommended two minutes
        </li>
        <li>Supports the user to use the optimal cleaning pressure</li>
        <li>
          Smile and frown icons on the display lets users know the overall
          effectiveness of their session
        </li>
      </ul>
      <p>
        The new Oral-B app provides 3D graphics and the AI Brushing Recognition
        technology with artificial intelligence recognizes all areas within the
        mouth, guiding users toward 100 percent brushing area completion.
      </p>
    </>
  ),

  MAGNETIC_HEAD: "LINEAR MAGNETIC DRIVE",
  MAGNETIC_DESC:
    "Linear magnetic drive\n\nOral-B iO™ was completely redesigned. The linear magnetic drive transfers the energy directly towards the bristle tips - concentrated energy right where it is needed most for a precise brushing experience. The handle lies calm in the hand and allows the toothbrush to glide smoothly from tooth to tooth.",

  CHARGER_HEAD: "MAGNETIC CHARGER",
  CHARGER_DESC: (
    <p>
      The sleek magnetic charger provides a fast full charge in just ~3 hours.
      The brush display provides an easy-to-read battery strength indicator.
      Cutting-edge technology with a compact and stylish design.
    </p>
  ),

  SENSOR_PROMPT: "CLICK AND HOLD TO MOVE THE PEARL TOWARDS THE BRUSH.",

  MOVE_HEAD: "Move the Oral-B iO™ around!",

  MOVE_TOUCH_DESC:
    "Simply use your finger and click, hold and drag to move the Oral-B iO™ and view it from all angles.",

  MOVE_MOUSE_DESC:
    "Simply use your mouse and click, hold and drag to move the Oral-B iO™ and view it from all angles.",

  ZOOM_HEAD: "Zoom in and out!",

  ZOOM_TOUCH_DESC:
    "Simply use a pinching motion with your fingers to zoom in and out of the Oral-B iO™.",

  ZOOM_MOUSE_DESC:
    "Simply use the scroll-wheel on your mouse to zoom in and out of the Oral-B iO™.",

  COMPARE_SIZE: "Size and weight compared to an iPhone 13 mini.",

  TEN_MATERIAL_COSMIC: "Cosmic Black",
  TEN_MATERIAL_STARDUST: "White",
  TEN_MATERIAL_PLAIN: "Plain Black",

  TEN_IOSENSE_HEAD: "iOsense™",
  TEN_IOSENSE_DESC: (
    <>
      <p>
        The NEW iOsense™ device is powered by artificial intelligence to
        intuitively guide your brushing and provide a personalized brushing
        experience like no other. It guides you...
      </p>

      <ul>
        <li>
          When to brush: the iOsense™ has a timer for optimal brushing time and
          a Wi-Fi clock
        </li>
        <li>
          Where to brush: the intuitive iOsense™ lights mean no blindspots in
          your mouth
        </li>
        <li>
          How to brush: The Oral-B App tracks your goals and provides
          personalizes feedback after every brushing
        </li>
      </ul>

      <p>
        The iOsense™ is also a magnetic charger that charges your brush in about
        3 hours. For users on-the-go, it comes with the charging travel case,
        meaning you can charge at your leisure.
      </p>
    </>
  ),
};
