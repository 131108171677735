import React, {useEffect} from 'react';
import {useTranslatedText, useControlOptions, useSpeed, useBrush, useSeries} from "../hooks";
import {ScrollWrapper} from '../components';

function Sensor() {

    const [, {setBrushRotation, setBrushPosition}] = useBrush()

    const [TEXT] = useTranslatedText();

    const series = useSeries()

    const [, {enable, move}] = useControlOptions();

    const [,setSpeed] = useSpeed();

    useEffect(() => {

        setBrushRotation([Math.PI/1.5,Math.PI,0]);

        setBrushPosition([0,19,-24]);

        setSpeed(4);

        enable(false);

        move([0,12,12]);

    }, []);

    return (

        <ScrollWrapper
            redirect={'SCENE_TECHNOLOGY'}
            headline={TEXT.SENSOR_HEAD}
        >

            <div className={'row mb-5'}>
                <div className="col">
                    <img src={series.sensor.image_1} alt=""/>
                </div>
            </div>

            <div className="row mb-5">
                <div className="col">
                    {TEXT.SENSOR_DESC}
                </div>
            </div>

            <div className={'row mb-5'}>
                <div className="col">
                    <img src={series.sensor.image_2} alt=""/>
                </div>
            </div>

        </ScrollWrapper>

    )

}

export default Sensor
