import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei/core/useGLTF'
import model from '../../assets/models/phone.glb'
import {useBrush} from "../hooks/index.js";
import {sRGBEncoding} from "three"

// https://codesandbox.io/s/ground-reflections-and-video-textures-7pd6v?file=/src/App.js

export default function Model(props) {

    const group = useRef();

    const { nodes, materials } = useGLTF(model,"./draco/gltf/");

    const [{video, clock},{setElapsedTime, resetElapsedTime}] = useBrush()

    const handler = (event) => {
        const {currentTime} = event.target
        if (currentTime > 0) setElapsedTime(0.00001)
        if (currentTime > 5.9) setElapsedTime(1)
        if (currentTime > 7.5) setElapsedTime(25)
        if (currentTime > 9.7) setElapsedTime(50)
        if (currentTime > 11.7) setElapsedTime(75)
        if (currentTime > 13.6) setElapsedTime(105)
        if (currentTime > 15.7) setElapsedTime(120)
        if (currentTime > 18) setElapsedTime(121)
        if (currentTime > 19) setElapsedTime(122)
        if (currentTime > 20) setElapsedTime(123)
        if (currentTime > 21) setElapsedTime(124)
    }

    useEffect(() => {
        return () => {
            video.removeEventListener('timeupdate', handler);
            video.pause()
            clock.start()
            resetElapsedTime()
        }
    }, [])

    useEffect(() => {

        video.addEventListener('timeupdate', handler);

    }, [video]);

    return (
        <group ref={group} {...props} dispose={null}>
            <group rotation={[-Math.PI / 2, 0, 0]} scale={[1,1,1]}>
                <mesh
                    geometry={nodes.ME_iPhoneX_Crystals.geometry}
                    material={materials.MA_Phone_Crystal}
                />
                <mesh
                    geometry={nodes.ME_iPhoneX_Holes.geometry}
                    material={materials.MA_Phone_Black}
                />
                <mesh
                    geometry={nodes.ME_iPhoneX_Frame.geometry}
                    material={materials.MA_Phone_Black_Glossy}
                />
                <mesh
                    geometry={nodes.ME_iPhoneX_Sensor.geometry}
                    material={materials.MA_Phone_Orange}
                />
                <mesh
                    geometry={nodes.ME_iPhoneX_Case.geometry}
                    material={materials.MA_Phone_Frame}
                />
                <mesh
                    geometry={nodes.ME_iPhoneX_Back.geometry}
                    material={materials.MA_Phone_Back}
                />
                <mesh
                    geometry={nodes.ME_iPhoneX_Glass.geometry}
                    material={materials.MA_Phone_Glass}
                />
                <mesh
                    geometry={nodes.ME_iPhoneX_Screen.geometry}
                >
                    <meshBasicMaterial
                        toneMapped={false}
                    >
                        <videoTexture attach={'map'} args={[video]} flipY={false} encoding={sRGBEncoding}/>
                    </meshBasicMaterial>
                </mesh>
            </group>

        </group>
    )
}
