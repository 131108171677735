import React, { useEffect } from "react";
import {
  useTranslatedText,
  useControlOptions,
  useDisplay,
  useButtons,
  usePrevious,
  useHashLocation,
} from "../hooks";
import { Html } from "@react-three/drei/web/Html";
import { animated, useSpring, config } from "react-spring";

function delay(duration) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), duration);
  });
}

function Intro() {
  const [TEXT] = useTranslatedText();

  const [, { enable, move, lookAt }] = useControlOptions();

  const [, setDisplay] = useDisplay();

  const [power, setPower, , setWorld] = useButtons();

  const [, navigate] = useHashLocation();

  const prevPower = usePrevious(power);

  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: Math.abs(power - 1) },
    config: config.gentle,
  });

  useEffect(() => {
    enable(false);

    lookAt([0, 13, 0]);

    move([0, 13, 10]);

    setDisplay("HELLO_SMILE_2");

    if (power === 1 && prevPower !== undefined && power !== prevPower) {
      Promise.resolve()
        .then(() => delay(800))
        .then(() => {
          setDisplay("HELLO_SMILE_1");
        })
        .then(() => delay(400))
        .then(() => setDisplay("HELLO_SMILE_2"))
        .then(() => delay(400))
        .then(() => navigate("SCENE_TECHNOLOGY"))
        .then(() => delay(400))
        .then(() => setWorld(1));
    }

    if (power === 1 && prevPower === undefined) {
      setWorld(0);
      setPower(0);
    }
  }, [power]);

  return (
    <Html
      center
      position={[0, 11.8, 2.0]}
      className={"content-text"}
      style={{ width: "20rem", textAlign: "center" }}
    >
      <animated.p style={{ opacity: spring.opacity }}>
        {TEXT.PRESS_POWER}
      </animated.p>
    </Html>
  );
}

export default Intro;
