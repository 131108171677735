import create from 'zustand';

const useStore = create(set => {
    return {
        material: 0,
        setMaterial: (value) => set((state) => ({material: value})),
    }
})

const useMaterial = () => {

    return [
        useStore(state => state.material),
        useStore(state => state.setMaterial),
    ];

};

export default useMaterial;