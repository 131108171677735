import {useControlOptions} from "../hooks";
import {useEffect} from "react";

const OpenTutorial = ({delay}) => {

    const [, {toggleTutorial}] = useControlOptions();

    useEffect(() => {

        const timer = setTimeout(() => toggleTutorial(true), delay)

        return () => {
            clearTimeout(timer)
            toggleTutorial(false)
        }

    }, [])

    return null

}

export default OpenTutorial
