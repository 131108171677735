import create from 'zustand';

const useStore = create(set => ({
    power: 0,
    setPower: (value) => set((state) => ({power: value})),
    world: 0,
    setWorld: (value) => set((state) => ({world: value})),
}))

const useButtons = () => {

    return [
        useStore(state => state.power),
        useStore(state => state.setPower),
        useStore(state => state.world),
        useStore(state => state.setWorld),
    ];

};

export default useButtons;
