import React, { useEffect, useState } from "react";
import { useTranslatedText } from "../hooks";

export const langs = {
  "en-US": "English",
  "de-DE": "Deutsch",
  "fr-FR": "Francais",
  "es-ES": "EspaÃ±ol",
  "it-IT": "Italian",
  "nl-NL": "Dutch",
  "se-SE": "Swedish",
  "no-NO": "Norwegian",
  "fi-FI": "Finnish",
  "dk-DK": "Danish",
  "pl-PL": "Polish",
  "tr-TR": "Turkish",
  "hu-HU": "Hungarian",
  "cz-CZ": "Check",
  "pt-BR": "Brazil",
  "jp-JP": "Japanese",
  "kr-KR": "Korean",
  "fr-CA": "Canadian French",
  "es-LATAM": "Latin America",
  "pt-PT": "Portuguese",
};

const LanguageSwitch = () => {
  const [, language, setLanguage] = useTranslatedText();

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    console.log(toggle);
  }, [toggle]);

  const handleClick = (value) => {
    setToggle((toggle) => !toggle);

    setLanguage(value);
  };

  return (
    <div
      className={"ms-auto"}
      style={{
        display: "flex",
        alignItems: "baseline",
        flexDirection: "column",
        pointerEvents: "all",
        position: "absolute",
        right: 0,
        bottom: 0,
        backgroundColor: toggle ? "#353f6e" : "transparent",
        padding: "0.5rem",
        borderRadius: "1rem",
      }}
    >
      {Object.entries(langs).map(([code, name], i) => (
        <button
          key={i}
          className={"btn"}
          style={{
            color: "white",
            textDecoration: "none",
            display: !toggle && language !== code ? "none" : "block",
          }}
          onClick={() => handleClick(code)}
        >
          {name}
        </button>
      ))}

      <svg
        className=""
        viewBox="0 0 32 16"
        style={{
          color: "white",
          position: "absolute",
          right: "-0.5rem",
          bottom: "0.65rem",
          margin: "0.675rem 0rem",
          width: "0.8rem",
          height: "0.8rem",
          fontSize: "0.8rem",
          fill: "#ffffff",
          transform: `rotate(${toggle ? "0deg" : "180deg"})`,
        }}
        role="img"
        aria-label="ob-icon-rounded-container-undefined ob-language-selector-toggle-icon"
      >
        <polygon
          className="st0"
          points="15,17.1 0,6.9 4.7,0 15,6.9 25.3,0 30,6.9 "
        >
          {" "}
        </polygon>
      </svg>
    </div>
  );
};

export default LanguageSwitch;
