import React, {useEffect} from 'react';
import {useTranslatedText, useControlOptions, useBrush, useSeries} from "../hooks";
import {ScrollWrapper} from "../components";

function Charger() {

    const [, {setBrushRotation, setBrushPosition}] = useBrush()

    const [TEXT] = useTranslatedText();

    const [, {move, lookAt}] = useControlOptions();

    const series = useSeries()

    useEffect(() => {

        setBrushRotation([-0.1,0,0]);

        setBrushPosition([0,3,-1]);

        setTimeout(() => {
            setBrushPosition([0,0,0])
            setBrushRotation([0,0,0]);
        }, 2000);

        move([10,10,25]);

        lookAt([0,3,0]);

    }, []);

    return (

        <ScrollWrapper
            redirect={'SCENE_TECHNOLOGY'}
            headline={TEXT[series.charger.head_key]}
        >

            <div className={'row mb-5'}>
                <div className="col">
                    <img src={series.charger.image_1} alt="" />
                </div>
                <div className="col">
                    <img src={series.charger.image_2} alt="" />
                </div>
            </div>

            <div className="row mb-5">
                <div className="col">
                    {TEXT[series.charger.desc_key]}
                </div>
            </div>

        </ScrollWrapper>

    )

}

export default Charger
