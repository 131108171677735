import React, { useRef, useMemo } from 'react'
import { useGLTF } from '@react-three/drei/core/useGLTF'
import {
    FadingRipplesMaterial,
    HousingMaterial,
    LogoMaterial,
} from '../materials';
import { useFrame } from "@react-three/fiber";
import { Router, Route } from 'wouter';
import {useHashLocation, useSpeed, useBrushhead, useButtons, useBrush} from "../hooks";
import {PointParticles} from "../components";
import model from '../../assets/models/21207_refill_sensitive.glb';

function Model() {

    const { nodes, materials } = useGLTF(model,"./draco/gltf/");

    const [{data}] = useBrush();

    const brushHead = useRef();

    const [speed] = useSpeed();

    const [brush,,color] = useBrushhead();

    const [power] = useButtons();

    const rippleMat = useMemo(() => new FadingRipplesMaterial(), []);

    useFrame(({clock}) => {
        rippleMat.iTime = clock.elapsedTime;
        rippleMat.speed = speed*5;
        rippleMat.period = speed/12;
        if (power) brushHead.current.rotation.z = Math.sin(speed*clock.elapsedTime*5)*0.4;
        if (power) brushHead.current.position.z = Math.sin(speed*clock.elapsedTime*5)*0.03;
    });

    return (
        <group position={[0, 15.64, -0.41]} dispose={null}>

            <group position={[0,7.35,0.5]} dispose={null}>
                <group ref={brushHead}>
                    <mesh geometry={nodes.Ultimative.geometry} material={materials['Bürste ']} visible={brush === 0}/>
                    <mesh geometry={nodes.Sensitive.geometry} material={materials['Bürste ']} visible={brush === 1}/>
                    <mesh geometry={nodes.Plate.geometry}>
                        <HousingMaterial color={data.materials[brush === 1 ? 1 : color].color} textured={false} />
                    </mesh>
                    <mesh geometry={nodes.Drive.geometry}>
                        <meshBasicMaterial color={0x000000}/>
                    </mesh>
                </group>
                <mesh geometry={nodes.Drive_Cap.geometry} material={nodes.Drive_Cap.material} />
                <mesh geometry={nodes.Pin.geometry} material={nodes.Pin.material} />
                <mesh geometry={nodes.Logo.geometry}>
                    <LogoMaterial color={color === 1 ? 0x999999 : 0xffffff}/>
                </mesh>
                <mesh geometry={nodes.Hard.geometry} material={nodes.Hard.material}>
                    <HousingMaterial color={data.materials[brush === 1 ? 1 : color].color} textured={false} />
                </mesh>
                <mesh geometry={nodes.Crossbar.geometry} material={nodes.Crossbar.material} />

                <Router hook={useHashLocation}>

                    <Route path={'SENSOR_HEAD'}>
                        <PointParticles count={speed*speed*20} speed={speed*speed/400} position={[0,0,1]} lifetime={0.8}/>
                        <mesh rotation={[0,0,0]} position={[0,0,1]} material={rippleMat}>
                            <planeBufferGeometry args={[6,6]}/>
                        </mesh>
                    </Route>

                    <Route path={'TECHNOLOGY_HEAD'}>
                        <PointParticles count={speed*50} speed={speed/100} position={[0,0,1]} lifetime={0.8}/>
                        <mesh rotation={[0,0,0]} position={[0,0,1]} material={rippleMat}>
                            <planeBufferGeometry args={[6,6]}/>
                        </mesh>
                    </Route>

                </Router>

            </group>

        </group>
    )
}

export default Model

useGLTF.preload(model,"./draco/gltf/")
