import { useEffect, useCallback, useMemo, useState, useRef } from "react";
import {
  RepeatWrapping,
  TextureLoader,
  Vector2,
  FrontSide,
  CanvasTexture,
} from "three";
import { useLoader, useFrame } from "@react-three/fiber";
import { useSpring, animated, config } from "@react-spring/three";
import plastic_normal from "../../assets/images/noise-roughness.jpg";
import noise from "../../assets/images/noise.png";
import series10texture from "../../assets/images/neonblack_map.jpg";
import { useButtons, useDissolve, useMaterial, useBrush } from "../hooks";

import housingColorReplacement from "./housing_color_replace.glsl";

// use canvas Texture as map https://spectrum.chat/react-three-fiber/general/html-canvas-as-texture~05413b03-cb63-45bb-a0fe-b78056aa7ebe
// draw and fade images on canvas https://stackoverflow.com/questions/33558354/canvas-image-animation-crossfade

// Dusnan Bosnjak helped me with compiling :)

/**
 * HACK:
 * this is actually "onBeforeParse" along with "onBeforeCompile"
 * for this effect to work, it would actually be beneficial to do it
 * in "onAfterParse" but before compilation,
 * it is safe to pre parse the includes which we can do like so:
 */
/*var pattern = /#include <(.*)>/gm

function parseIncludes( string ){
    function replace( match , include ){
        var replace = THREE.ShaderChunk[ include ]
        return parseIncludes( replace )
    }
    return string.replace( pattern, replace )
}*/

const from = `#include <transmission_fragment>`;
const to = `
    
    float transmissionAlpha = 1.0;
    float transmissionFactor = 0.0;
    float thicknessFactor = thickness;
    
    float gradient = 0.0;
    float transTex = 0.0;
    float dissolve = 0.0;
    float glowing = 0.0;
    vec3 glowingColor = vec3(0.0,0.25,1.0);
    float size = 1.0;
    vec2 noiseRepeat = vec2(1.0,1.0);
    
    gradient = smoothstep(u_xPos - size * 0.5, u_xPos + size * 0.5, vWorldPosition.y);
    
    #ifdef USE_TRANSMISSIONMAP
        transTex = texture2D( transmissionMap, vec2(vUv.x * noiseRepeat.x + u_time * 0.02, vUv.y * noiseRepeat.y + u_time * 0.1) ).r * 0.99 + 0.009;
        dissolve = step(transTex, gradient);
        glowing = step(transTex+0.05, gradient);
        transmissionFactor = step(transTex, dissolve);
    #endif
    
    totalEmissiveRadiance = mix(vec3(0.0,0.0,0.0), glowingColor, glowing - dissolve);
    
    #ifdef USE_THICKNESSMAP
        thicknessFactor *= texture2D( thicknessMap, vUv ).g;
    #endif
    
    material.diffuseColor = mix(material.diffuseColor, glowingColor, dissolve);
    // whiten the view otherwise black diffuse will make everything dark
    material.diffuseColor = mix(material.diffuseColor, vec3(1.0,1.0,1.0), glowing);
    
    vec3 pos = vWorldPosition;
    vec3 v = normalize( cameraPosition - pos );
    vec3 n = inverseTransformDirection( normal, viewMatrix );
    //clear the rougness map
    roughnessFactor = mix(roughnessFactor, 0.0, glowing);
    vec4 transmission = getIBLVolumeRefraction(n, v, roughnessFactor, material.diffuseColor, material.specularColor, material.specularF90, pos, modelMatrix, viewMatrix, projectionMatrix, ior, thicknessFactor, attenuationColor, attenuationDistance );

    totalDiffuse = mix( totalDiffuse, transmission.rgb, transmissionFactor );
    transmissionAlpha = mix( transmissionAlpha, transmission.a, transmissionFactor );  
    `;

const from2 = `vec4 diffuseColor = vec4( diffuse, opacity );`;
const to2 = housingColorReplacement;

const HousingMaterial = ({
  color,
  textured = true,
  roughness = 0.5,
  name = "default",
}) => {
  const [{ data }] = useBrush();

  const [xPosition] = useDissolve();

  const [, , world] = useButtons();

  const [lastMaterial, setLastMaterial] = useState(0);
  const [lastColor, setLastColor] = useState(0x172129);

  const [normalMap, noiseMap, series10map] = useLoader(TextureLoader, [
    plastic_normal,
    noise,
    series10texture,
  ]);

  normalMap.wrapS = RepeatWrapping;
  normalMap.wrapT = RepeatWrapping;
  normalMap.repeat = new Vector2(2, 2);

  noiseMap.wrapS = RepeatWrapping;
  noiseMap.wrapT = RepeatWrapping;

  series10map.wrapS = RepeatWrapping;
  series10map.wrapT = RepeatWrapping;

  const [material] = useMaterial();

  const spring = useSpring({
    from: {
      envMapIntensity: 0,
      xPos: 20,
    },
    to: {
      envMapIntensity: world,
      xPos: xPosition,
      color: color || data.materials[material].color,
    },
    // color: color || data.materials[material].color,
    config: config.molasses,
    onChange: ({ value }) => {
      uniforms.u_xPos.value = value.xPos;
    },
  });

  const main = useRef(document.createElement("canvas"));
  main.current.width = 512;
  main.current.height = 512;
  const last = useRef(main.current.cloneNode(true).getContext("2d"));
  const next = useRef(main.current.cloneNode(true).getContext("2d"));

  const ctx = useRef(main.current.getContext("2d"));

  const op = useRef(1);

  const diffuse = useRef(new CanvasTexture(ctx.current.canvas));
  diffuse.current.wrapS = RepeatWrapping;
  diffuse.current.wrapT = RepeatWrapping;

  const fade = () => {
    // if (op.current <= 0) return;
    // op.current -= 0.025;
    // last.current.clearRect(0, 0, main.current.width, main.current.height);
    // last.current.globalAlpha = op.current;
    // last.current.fillStyle = lastColor || data.materials[lastMaterial].color;
    // last.current.fillRect(0, 0, main.current.width, main.current.height);
    // if (textured && data.materials[lastMaterial].map)
    //   last.current.drawImage(data.materials[lastMaterial].map, 0, 0);
    // next.current.clearRect(0, 0, main.current.width, main.current.height);
    // next.current.globalAlpha = 1 - op.current;
    // next.current.fillStyle = color || data.materials[material].color;
    // next.current.fillRect(0, 0, main.current.width, main.current.height);
    // if (textured && data.materials[material].map)
    //   next.current.drawImage(data.materials[material].map, 0, 0);
    // ctx.current.clearRect(0, 0, main.current.width, main.current.height);
    // ctx.current.drawImage(last.current.canvas, 0, 0);
    // ctx.current.drawImage(next.current.canvas, 0, 0);
    // diffuse.current.needsUpdate = true;
    // if (op.current <= 0) {
    //   setLastMaterial(material);
    //   setLastColor(color);
    // }
  };

  useEffect(() => {
    op.current = 1;
  }, [material, color]);

  const uniforms = useMemo(() => {
    return {
      u_time: { type: "f", value: 0.0 },
      u_xPos: { type: "f", value: 26.0 },
      u_sparklesIntesity: {
        type: "f",
        value: textured && data.materials[material].map ? 1.0 : 0,
      },
      u_series10map: { type: "sampler2D", value: series10map },
    };
  }, []);

  useFrame((state, delta) => {
    // fade();
    uniforms.u_time.value += delta;
  });

  const onBeforeCompile = useCallback(
    (shader) => {
      shader.uniforms.u_time = uniforms.u_time;
      shader.uniforms.u_xPos = uniforms.u_xPos;
      shader.uniforms.u_sparklesIntesity = uniforms.u_sparklesIntesity;
      shader.uniforms.u_series10map = uniforms.u_series10map;
      shader.fragmentShader =
        "uniform float u_time;\nuniform float u_xPos;\nuniform sampler2D u_series10map;\nuniform float u_sparklesIntesity;\n" +
        shader.fragmentShader;
      shader.fragmentShader = shader.fragmentShader.replace(from, to);
      shader.fragmentShader = shader.fragmentShader.replace(from2, to2);
    },
    [uniforms]
  );

  const handleBeforeCompile = useCallback(onBeforeCompile, [onBeforeCompile]);

  return (
    <animated.meshPhysicalMaterial
      name={"Housing " + name}
      //   map={diffuse.current}
      roughness={roughness}
      ior={1}
      metalness={0} // 0.35
      roughnessMap={normalMap}
      reflectivity={0.3}
      envMapIntensity={spring.envMapIntensity}
      onBeforeCompile={handleBeforeCompile}
      transmission={0.1}
      transmissionMap={noiseMap}
      side={FrontSide}
      color={spring.color}
    />
  );
};

export default HousingMaterial;
