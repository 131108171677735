import React from 'react'
import { useGLTF } from '@react-three/drei/core/useGLTF'
import model from '../../assets/models/211205_charger.glb';
import { HousingMaterial } from '../materials';
import {FrontSide} from 'three';
import {animated, config, useSpring} from "@react-spring/three";
import {useButtons, useMaterial, useBrush} from "../hooks";
import useHashLocation from "../hooks/use-hash-location.js";

export default function Model() {

    const { nodes } = useGLTF(model,"./draco/gltf/");

    const [{data}] = useBrush();

    const [,,world] = useButtons();

    const [material] = useMaterial();

    const [location] = useHashLocation();

    const {envMapIntensity, position} = useSpring({
        envMapIntensity: world,
        position: location === 'MEASUREMENTS' ? [0,-6,0] : [0,0,0],
        config: config.molasses,
    });

    return (
        <animated.group dispose={null} position={position}>
            <mesh geometry={nodes.Cylinder.geometry}>
                <animated.meshPhysicalMaterial
                    //color={0x222222}
                    color={material === 0 ? 0x222222 : 0xffffff}
                    roughness={0.7}
                    metalness={0}
                    envMapIntensity={envMapIntensity}
                    side={FrontSide}
                />
            </mesh>
            <mesh geometry={nodes.Cylinder_1.geometry}>
                <HousingMaterial
                    color={material === 2 ? data.materials[1].color : data.materials[material].color}
                />
            </mesh>
        </animated.group>
    )
}

useGLTF.preload(model,"./draco/gltf/")
