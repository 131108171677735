import { useEffect, useState } from "react";
import { useMemo } from "react";
import useTranslatedText from "./use-translated-text";
import { langs } from "../components/LanguageSwitch";

const defaultValues = {
  series: "9",
  lang: "en-US",
  embed: true,
};

export const on = (obj, ...args) => {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...args);
  }
};

export const off = (obj, ...args) => {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...args);
  }
};

const useUrlParams = () => {
  const [value, setValue] = useState(
    new URLSearchParams(window.location.search)
  );

  const [, language, setLanguage] = useTranslatedText();

  useEffect(() => {
    const onChange = () => {
      setValue(new URLSearchParams(window.location.search));
    };

    on(window, "popstate", onChange);
    on(window, "pushstate", onChange);
    on(window, "replacestate", onChange);

    return () => {
      off(window, "popstate", onChange);
      off(window, "pushstate", onChange);
      off(window, "replacestate", onChange);
    };
  }, []);

  const params = useMemo(() => {
    const obj = Object.fromEntries([...value]);

    return Object.assign(defaultValues, obj);
  }, [value]);

  useEffect(() => {
    if (params.lang in langs) {
      setLanguage(params.lang);
    } else {
      console.warn("Language param is not a valid identifier");
    }
  }, [params]);

  return params;
};

export default useUrlParams;
