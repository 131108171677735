import React, {useRef} from 'react';
import {useFrame} from '@react-three/fiber';
import {Object3D, Vector3} from 'three';

const _positionCache = new Vector3(0,0,0);

const _rotationCache = []

for (let i = 0; i < 200; i++) {

    _rotationCache.push(new Vector3().random().subScalar( 0.5 ).normalize().clamp({x: -1,y:-1,z:-0.1}, {x: 1,y:1,z:0.1}))

}

function ParticleSystem({ count, lifetime = 0.6, ...props }) {

    let _count = count > 1000 ? 1000 : count;

    const mesh = React.useRef();

    const dummy = React.useMemo(() => new Object3D(), []);

    const particles = React.useMemo(() => {
        return [
            {
                position: _positionCache,
                rotation: _rotationCache[Math.floor(Math.random()*_rotationCache.length)],
                scale: {x: 0.1, y: 0.1, z: 0.1},
                speed: 1,
                lifetime,
                _elapsedTime: 0,
            }
        ]
    }, [lifetime])

    const remove = (particle) => {

        const index = particles.indexOf(particle);

        particles.splice(index, 1);

        return true;

    }

    const time = useRef(0);

    let _timeSinceLastSpawn = null;

    useFrame((stuff, delta) => {

        particles.forEach((particle) => {

            particle._elapsedTime += delta;

            if (particle._elapsedTime >= particle.lifetime) {

                remove(particle);

            }

        })

        if (particles.length < _count && (_timeSinceLastSpawn === null || _timeSinceLastSpawn > lifetime/_count)) {

            for (let i = 0; i < Math.max(Math.floor(delta / (lifetime/_count)), 1); i++) {

                particles.push(
                    {
                        position: new Vector3(0,0,0),
                        rotation: _rotationCache[Math.floor(Math.random()*_rotationCache.length)],
                        scale: {x: 0.1, y: 0.1, z: 0.1},
                        speed: 1,
                        lifetime,
                        _elapsedTime: 0,
                    })

            }

            _timeSinceLastSpawn = 0;

        }

        particles.forEach((particle, i) => {

            const {
                position,
                rotation,
                speed,
                _elapsedTime,
            } = particle;

            dummy.position.copy(position);
            dummy.translateOnAxis(rotation, speed * _elapsedTime)
            position.copy(dummy.position);
            dummy.updateMatrix()
            mesh.current.setMatrixAt(i, dummy.matrix);

        });

        mesh.current.instanceMatrix.needsUpdate = true

        time.current += delta;

        _timeSinceLastSpawn += delta;

    })

    return (
        <instancedMesh ref={mesh} args={[null, null, _count]} {...props}>
            <planeBufferGeometry/>
            <pointsMaterial size={0.1} />
        </instancedMesh>
    )
}

export default ParticleSystem
