import {ShaderMaterial} from 'three';
import {extend} from "@react-three/fiber"

export default class FadingRipplesMaterial extends ShaderMaterial {
    constructor() {
        super({
            uniforms: {
                iTime: { value: 0 },
                iSpeed: { value: 1 },
                period: { value: 0.5 },
            },
            vertexShader: `
                varying vec2 vUv;
                void main() {
                    vUv = uv;
                    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
                }
            `,
            fragmentShader: `
                #define M_PI 3.1415926535897932384626433832795
            
                varying vec2      vUv;
                uniform float     iSpeed;
                uniform float     iTime;
                uniform float     period;
                
                void mainImage(out vec4 fragColor, in vec2 fragCoord) {
                    float direction = -1.0;
                    float scale = 100.0 * direction;
                    float phaseLength = iSpeed/100.0;
                    float innerGlow = 0.0;
                    float blurScale = 100.0;
                    float lineWidth = 1.5;
                    float blurCut = blurScale - lineWidth / M_PI;
                    float width = 10.0;
                    float falloff = -0.0;
                    float intensity = 20.0;
                    float fade = 0.8; // fade transparency from center, 1.0 matches bounds, lower to center
                    
                    vec2 center =  vec2(0.5, 0.5);
                    
                    float amplitude = distance(fragCoord.xy,center);
                    
                    vec2 uv = 2.0*vUv-1.0;
                    
                    float offset = length(uv);
                    
                    float x = offset*scale;
                    
                    float a = iTime*iSpeed;
                    
                    float value = clamp(sin(period * x + a) - sin((M_PI / period / 2.0 - lineWidth / 2.0)*period), 0.0, 1.0);
                    
                    float alpha = (value - x * falloff) * (fade - offset) * intensity;
                    
                    fragColor = vec4(0.5 * pow(offset, 2.0), 0.75, 1.0, alpha);
                    
                }
                
                void main() {
                    mainImage(gl_FragColor, gl_FragCoord.xy);
                }
            `,
            transparent: true,
            //side: THREE.DoubleSide,
            //depthTest: true
        })

    }

    get iTime() {
        return this.uniforms.iTime.value
    }

    set iTime(v) {
        return (this.uniforms.iTime.value = v)
    }

    get speed() {
        return this.uniforms.iSpeed.value
    }

    set speed(v) {
        return (this.uniforms.iSpeed.value = v)
    }

    get period() {
        return this.uniforms.period.value
    }

    set period(v) {
        return (this.uniforms.period.value = v)
    }
}

extend({FadingRipplesMaterial})
