import { createRoot } from 'react-dom/client';
import App from './js/App.jsx'

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);

document.body.addEventListener("touchstart", function (e) {
    if (e.target instanceof HTMLCanvasElement) {
        e.preventDefault();
    }
}, { passive: false });
document.body.addEventListener("touchend", function (e) {
    if (e.target instanceof HTMLCanvasElement) {
        e.preventDefault();
    }
}, { passive: false });
document.body.addEventListener("touchmove", function (e) {
    if (e.target instanceof HTMLCanvasElement) {
        e.preventDefault();
    }
}, { passive: false });
