import {Color, TextureLoader, ShaderMaterial, AdditiveBlending} from 'three';
import point from "../../assets/images/point_particle.png";

const uniforms = {
    color: { value: new Color( 0xffffff ) },
    pointTexture: { value: new TextureLoader().load(point) }
};

const material = new ShaderMaterial( {

    uniforms:       uniforms,
    vertexShader:   `
        attribute float size;
        attribute vec3 customColor;
        varying vec3 vColor;

        void main() {
        
            vColor = customColor;
        
            vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        
            gl_PointSize = size * ( 300.0 / -mvPosition.z );
        
            gl_Position = projectionMatrix * mvPosition;

        }
    `,

    fragmentShader: `
        uniform vec3 color;
        uniform sampler2D pointTexture;
        varying vec3 vColor;

        void main() {

            gl_FragColor = vec4( color * vColor, 1.0 );
            gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );

        }
    `,

    blending: AdditiveBlending,
    depthTest: true,
    transparent: true

});

export default material
