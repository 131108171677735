import React from "react";
import { Html } from "@react-three/drei/web/Html";
import { Link, Router } from "wouter";
import { useHashLocation, useControlOptions } from "../hooks";
import { useThree } from "@react-three/fiber";
import { animated, config, useSpring } from "@react-spring/web";

function Hotspot({
  text,
  color,
  uri,
  image = null,
  direction = "right",
  style,
  onClick,
  ...props
}) {
  const { camera } = useThree();

  const [{ measure }] = useControlOptions();

  const { opacity } = useSpring({
    opacity: measure ? 0 : 1,
    config: config.gentle,
    delay: measure ? 0 : 1000,
  });

  return (
    <mesh {...props} visible={false}>
      <sphereGeometry args={[0.5]} />
      <Html
        camera={camera}
        style={{ pointerEvents: measure ? "none" : "auto" }}
      >
        <animated.div
          className={`hotspot hotspot-${direction}`}
          style={{ opacity }}
        >
          <div className="hotspot-label">
            <Router hook={useHashLocation}>
              <Link
                to={uri}
                className={"hotspot-link"}
                style={{
                  ...style,
                }}
                onClick={onClick}
              >
                {text}
              </Link>
            </Router>
          </div>
          <div className="hotspot-spot"> </div>
          {image && <img className={"hotspot-image"} src={image} alt={""} />}
        </animated.div>
      </Html>
    </mesh>
  );
}

export default Hotspot;
