import React, { useEffect, useRef } from 'react';
import {extend, useThree} from "@react-three/fiber";
import {animated, config, useSpring, useChain, useSpringRef} from "@react-spring/three";
import {a} from "@react-spring/web";
import {useControlOptions, useBrush, useTranslatedText} from "../hooks";
import {Html} from '@react-three/drei/web/Html';
import {Line2} from "three/examples/jsm/lines/Line2";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial.js";
import {LineGeometry} from "three/examples/jsm/lines/LineGeometry.js";
import {LineSegments2} from "three/examples/jsm/lines/LineSegments2.js";
import {LineSegmentsGeometry} from "three/examples/jsm/lines/LineSegmentsGeometry.js";
import {IPhoneShape} from "../components";

extend({Line2, LineMaterial, LineGeometry, LineSegments2, LineSegmentsGeometry})

const height = 23.5
const width = 2.8

export default function Measurements() {

    const [{measure}, {move, enable, lookAt, setMeasure}] = useControlOptions();

    const [,{setBrushRotation}] = useBrush()

    const [TEXT] = useTranslatedText();

    const sizeH = useRef();

    const sizeW = useRef();

    useEffect(() => {

        enable(false)

        setBrushRotation([0, 0, 0])

        setMeasure(true)

        move([0,10,45]);

        lookAt([0,10.5,0]);

        return () => setMeasure(false)
    }, [])

    const { size } = useThree()

    const sizeSpring1Ref = useSpringRef()
    const sizeSpring1 = useSpring({
        ref: sizeSpring1Ref,
        scaleHY: measure ? 1 : 0,
        scaleWX: measure ? 1 : 0,
        factor: measure ? 1 : 0,
        opacity: measure ? 1 : 0,
        delay: measure ? 500 : 0,
        onStart: e => {
            if (measure === true) sizeW.current.visible = sizeH.current.visible = true
        },
        onRest: e => {
            if (measure === false) sizeW.current.visible = sizeH.current.visible = false
        },
        config: config.default,
    });

    const sizeSpring2Ref = useSpringRef()
    const sizeSpring2 = useSpring({
        ref: sizeSpring2Ref,
        scaleHX: measure ? 1 : 0.07,
        scaleWY: measure ? 1 : 0.07,
        opacity: measure ? 1 : 0,
        delay: measure ? 500 : 0,
        config: config.gentle,
    });

    useChain(measure ? [sizeSpring1Ref, sizeSpring2Ref] : [sizeSpring2Ref, sizeSpring1Ref], [
        0,
        measure ? 1 : 0.6,
    ])

    useEffect(() => {
        const offsetX = -.6
        const sizeHPoints = [
            offsetX+0,height/2,0,
            offsetX+.6,height/2,0,
            offsetX+.6,-height/2,0,
            offsetX+0,-height/2,0,
        ]
        sizeH.current.geometry.setPositions(sizeHPoints)

        const offsetY = .6
        const sizeWPoints = [
            -width/2,offsetY+0,0,
            -width/2,offsetY-.6,0,
            width/2,offsetY-.6,0,
            width/2,offsetY+0,0,
        ]
        sizeW.current.geometry.setPositions(sizeWPoints)

    }, [])

    return (
        <>

            <animated.group position={[2.7,height/2,0]}>
                <animated.group scale-y={sizeSpring1.scaleHY} scale-x={sizeSpring2.scaleHX}>
                    <line2 ref={sizeH} visible={false}>
                        <lineGeometry />
                        <lineMaterial linewidth={2} color={'white'} resolution={[size.width, size.height]} depthTest={false} depthWrite={false} transparent={true} />
                    </line2>
                </animated.group>

                <Html transform distanceFactor={15} style={{pointerEvents: 'none'}}>
                    <div className={'d-flex flex-column'} style={{marginLeft: '12rem', marginTop: '16rem'}}>

                        <div className={'d-flex justify-content-center'}>
                            <a.div className={'hotspot mb-5 d-inline-block'} style={{opacity: sizeSpring1.opacity}}>
                                <div className="hotspot-label position-relative d-inline-block mx-auto" style={{transform: 'translate(0, 0)', position: 'relative'}}>
                                    <a.span className="hotspot-link">
                                        {sizeSpring1.factor.to(n => (n * 23.5).toFixed(1))}
                                    </a.span>
                                    <span>cm</span>
                                </div>
                            </a.div>
                        </div>

                        <a.div className={'d-flex flex-column mb-4'} style={{opacity: sizeSpring2.opacity}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="58.621" height="52.758" viewBox="0 0 58.621 52.758" style={{height: '2rem'}} className={'mb-2 mx-auto'}>
                                <path id="weight" d="M31.31,3A11.724,11.724,0,0,1,43.034,14.724a11.4,11.4,0,0,1-1.583,5.862H48.9a5.876,5.876,0,0,1,5.716,4.572C60.5,48.636,60.621,49.252,60.621,49.9a5.862,5.862,0,0,1-5.862,5.862H7.862A5.862,5.862,0,0,1,2,49.9c0-.645.117-1.26,6.009-24.738a5.876,5.876,0,0,1,5.716-4.572h7.445a11.4,11.4,0,0,1-1.583-5.862A11.724,11.724,0,0,1,31.31,3m0,5.862a5.862,5.862,0,1,0,5.862,5.862A5.862,5.862,0,0,0,31.31,8.862Z" transform="translate(-2 -3)" fill="#737d9b"/>
                            </svg>
                            <span className="hotspot-link text-center">
                                140g
                            </span>
                        </a.div>

                        <a.div className={'d-flex justify-content-center flex-column'} style={{opacity: sizeSpring2.opacity}}>
                            <span className="hotspot-link text-center text-white-50" style={{maxWidth: '10rem'}}>
                                <small>* {TEXT.COMPARE_SIZE}</small>
                            </span>
                        </a.div>
                    </div>
                </Html>
            </animated.group>

            <animated.group position={[0,-1.5,0]}>
                <animated.group scale-x={sizeSpring1.scaleWX} scale-y={sizeSpring2.scaleWY}>
                    <line2 ref={sizeW} visible={false}>
                        <lineGeometry />
                        <lineMaterial linewidth={2} color={'white'} resolution={[size.width, size.height]} depthTest={false} depthWrite={false} transparent={true} />
                    </line2>
                </animated.group>

                <Html transform distanceFactor={15} style={{pointerEvents: 'none'}}>
                    <a.div className={'hotspot'} style={{opacity: sizeSpring1.opacity}}>
                        <div className="hotspot-label position-relative" style={{transform: 'translate(0,3rem)'}}>
                            <a.span className="hotspot-link">
                                {sizeSpring1.factor.to(n => (n * 2.8).toFixed(1))}
                            </a.span>
                            <span>cm</span>
                        </div>
                    </a.div>
                </Html>
            </animated.group>

            <group position={[-5.5,4.1,0]} rotation={[-0.1,0.4,0.1]}>
                <Html transform={true} distanceFactor={15}>
                    <a.div className={'d-flex justify-content-center flex-column'} style={{opacity: sizeSpring2.opacity}}>
                        <div style={{width: '40px'}}>
                            <IPhoneShape />
                        </div>
                    </a.div>
                </Html>
            </group>

        </>
    )

}
