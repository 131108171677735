import create from 'zustand';
import data from '../data/series.json';
import useUrlParams from "./use-url-params.js";
import appVideo from "../../assets/videos/app.mp4";
import {Clock} from "three";

data["10"].housing.materials.map(m => {
    if (!m.texture) return m;
    const image = new Image();
    image.src = new URL(`../../assets/images/${m.texture}`, import.meta.url).href;
    return m.map = image;
})

const useStore = create(set => {
    return {
        brushPosition: [0,0,0],
        setBrushPosition: (value) => set((state) => ({brushPosition: value})),
        brushRotation: [0,0,0],
        setBrushRotation: (value) => set((state) => ({brushRotation: value})),
        brushSpringConfig: {reset: false, config: 'molasses'},
        setBrushSpringConfig: (value) => set((state) => ({brushSpringConfig: value})),
        spinBrush: () => false,
        setSpinBrush: (value) => set((state) => ({spinBrush: value})),
        elapsedTime: null,
        setElapsedTime: (value) => set((state) => ({elapsedTime: value})),
        resetElapsedTime: () => set((state) => ({elapsedTime: null})),
        video: Object.assign(document.createElement('video'), { src: appVideo, playsInline: true, crossOrigin: 'Anonymous', loop: true }),
        clock: new Clock(true)
    }
})

const useBrush = () => {

    const params = useUrlParams()

    return [
        {
            data: data[params.series].housing,
            brushPosition: useStore(state => state.brushPosition),
            brushRotation: useStore(state => state.brushRotation),
            brushSpringConfig: useStore(state => state.brushSpringConfig),
            spinBrush: useStore(state => state.spinBrush),
            elapsedTime: useStore(state => state.elapsedTime),
            video: useStore(state => state.video),
            clock: useStore(state => state.clock),
        },
        {
            setBrushPosition: useStore(state => state.setBrushPosition),
            setBrushRotation: useStore(state => state.setBrushRotation),
            setBrushSpringConfig: useStore(state => state.setBrushSpringConfig),
            setSpinBrush: useStore(state => state.setSpinBrush),
            setElapsedTime: useStore(state => state.setElapsedTime),
            resetElapsedTime: useStore(state => state.resetElapsedTime),
        }
    ];

};

export default useBrush;
