import create from 'zustand';

const useStore = create(set => ({
    speed: 0,
    setSpeed: (value) => set((state) => ({speed: value})),
}))

const useSpeed = () => {

    return [
        useStore(state => state.speed),
        useStore(state => state.setSpeed),
    ];

};

export default useSpeed;