import { Draggable } from "../components";
import { Line } from "@react-three/drei";
import { Html } from "@react-three/drei/web/Html";
import React, { Suspense, useMemo } from "react";
import { useThree } from "@react-three/fiber";
import { useTranslatedText } from "../hooks";
import { isMobile } from "react-device-detect";

const SensorScene = () => {
  const { size } = useThree();

  const [TEXT] = useTranslatedText();

  const position = useMemo(() => {
    if (isMobile) return [0, 13.75, -4];
    else return [-2, 12, -4];
  }, [isMobile]);

  return (
    <>
      <Suspense fallback={null}>
        <Draggable />
      </Suspense>

      <group position={position}>
        {!isMobile && (
          <Line
            points={[0, 0, 0, 1.33, 0, 0]}
            color={"white"}
            lineWidth={3}
            resolution={[size.width, size.height]}
          />
        )}
        <Html style={{ background: "red" }}>
          <div
            style={{
              position: "absolute",
              right: "1rem",
              top: 0,
              transform: "translate(0, -50%)",
              width: "14rem",
              color: "white",
              fontSize: "larger",
              textAlign: "right",
            }}
          >
            <p className={"mb-0"} style={{whiteSpace:"pre-wrap"}}>{TEXT.SENSOR_PROMPT}</p>
          </div>
        </Html>
      </group>
    </>
  );
};

export default SensorScene;
