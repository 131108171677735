import create from 'zustand';

const useStore = create(set => ({
    enabled: false,
    position: [0,13,10],
    target: [0,13,0],
    moving: false,
    measure: false,
    tutorialOpen: false,
    tutorialDirty: false,
    enable: (value) => set((state) => ({enabled: value})),
    move: (value) => set((state) => ({position: value})),
    lookAt: (value) => set((state) => ({target: value})),
    setMoving: (value) => set((state) => ({moving: value})),
    setMeasure: (value) => set((state) => ({measure: value})),
    makeTutorialDirty: () => set((state) => ({tutorialDirty: true})),
    toggleTutorial: (value) => set((state) => ({tutorialOpen: value})),
}))

const useControlOptions = () => {

    return [
        {
            enabled: useStore(state => state.enabled),
            position: useStore(state => state.position),
            target: useStore(state => state.target),
            moving: useStore(state => state.moving),
            measure: useStore(state => state.measure),
            tutorialOpen: useStore(state => state.tutorialOpen && !state.tutorialDirty),
        },
        {
            enable: useStore(state => state.enable),
            move: useStore(state => state.move),
            lookAt: useStore(state => state.lookAt),
            setMoving: useStore(state => state.setMoving),
            setMeasure: useStore(state => state.setMeasure),
            makeTutorialDirty: useStore(state => state.makeTutorialDirty),
            toggleTutorial: useStore(state => state.toggleTutorial),
        }
    ];

};

export default useControlOptions;
