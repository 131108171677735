import { useSpring, animated, config } from '@react-spring/three';
import { useButtons } from "../hooks";

const Material = ({color = 0xffffff}) => {

    const [,,world] = useButtons();

    const spring = useSpring({
        from: { envMapIntensity: 0 },
        to: { envMapIntensity: world },
        config: config.molasses,
    });

    return (
        <animated.meshStandardMaterial
            name={'Logo Print'}
            color={color}
            roughness={0.9}
            envMapIntensity={spring.envMapIntensity}
        />
    )

}

export default Material
