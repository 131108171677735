import React, {useEffect, useRef, useState} from 'react'
import {useGLTF} from '@react-three/drei/core/useGLTF'
import model from '../../assets/models/220903_charger.glb';
import {animated, config, useSpring} from "@react-spring/three";
import {useFrame, useLoader, useThree} from "@react-three/fiber";
import {CanvasTexture, FrontSide, RepeatWrapping, TextureLoader, Vector2} from "three";
import charger_emissive from "../../assets/images/charger_lights_emissive.png";
import plastic_normal from "../../assets/images/noise-roughness.jpg";
import noise from "../../assets/images/noise.png";
import font from "../../assets/fonts/Technology.ttf";
import useHashLocation from "../hooks/use-hash-location";
import {useBrush, useButtons, useMaterial} from "../hooks/index.js";

const colorList = ['#1e64fc', '#00a0d5', 'white', 'red']

function delay(duration) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), duration);
    });
}

const LightBar = ({rotation, id, colorId, onBrushed}) => {

    const [
        charger_emissive_map,
    ] = useLoader(TextureLoader, [
        charger_emissive
    ]);

    const {nodes} = useGLTF(model,"./draco/gltf/");

    const {color} = useSpring({
        color: colorList[colorId],
        config: config.molasses,
    });

    return (
        <mesh geometry={nodes.lights_4.geometry} rotation={rotation}>
            <animated.meshStandardMaterial color={color} alphaMap={charger_emissive_map} transparent={true} emissive={color}/>
        </mesh>
    )

}

const LightsManager = () => {

    const [location] = useHashLocation()

    const [state1, setState1] = useState(0)
    const [state2, setState2] = useState(0)
    const [state3, setState3] = useState(0)
    const [state4, setState4] = useState(0)
    const [state5, setState5] = useState(0)
    const [state6, setState6] = useState(0)
    const [warning, setWarning] = useState(undefined)

    const reset = () => {
        setState1(0)
        setState2(0)
        setState3(0)
        setState4(0)
        setState5(0)
        setState6(0)
        if (location === 'charger') idle()
    }

    const idle = () => {
        return Promise.resolve()
            .then(() => delay(1000))
            .then(() => setState3(1))
            .then(() => delay(1200))
            .then(() => setState4(1))
            .then(() => delay(800))
            .then(() => setState5(1))
            .then(() => delay(1000))
            .then(() => setState2(1))
            .then(() => delay(1200))
            .then(() => setState1(1))
            .then(() => delay(800))
            .then(() => setWarning(3))
            .then(() => delay(3000))
            .then(() => setWarning(undefined))
            .then(() => delay(1200))
            .then(() => setState6(1))
            .then(() => delay(2000))
            .then(() => setState6(2))
            .then(() => delay(1000))
            .then(() => setState2(2))
            .then(() => delay(1200))
            .then(() => setState1(2))
            .then(() => delay(1200))
            .then(() => setState4(2))
            .then(() => delay(800))
            .then(() => setState5(2))
            .then(() => delay(800))
            .then(() => setState3(2))
            .then(() => delay(4000))
            .then(() => reset())
    }

    useEffect(() => {
        if (location === 'CHARGER_HEAD') idle()
    }, [location])

    return (
        <>
            <LightBar rotation={[0,-Math.PI*2/6*0+0.00,0]} colorId={warning || state1} />
            <LightBar rotation={[0,-Math.PI*2/6*1+0.06,0]} colorId={warning || state2} />
            <LightBar rotation={[0,-Math.PI*2/6*2+0.12,0]} colorId={warning || state3} />
            <LightBar rotation={[0,-Math.PI*2/6*3+0.00,0]} colorId={warning || state4} />
            <LightBar rotation={[0,-Math.PI*2/6*4+0.06,0]} colorId={warning || state5} />
            <LightBar rotation={[0,-Math.PI*2/6*5+0.12,0]} colorId={warning || state6} />
        </>
    )

}

function Charger() {

    const [location] = useHashLocation();

    const [{elapsedTime, clock}] = useBrush()

    const [power] = useButtons()

    const group = useRef();

    const { nodes, materials } = useGLTF(model,"./draco/gltf/");

    const [fontLoaded, setFontLoaded] = useState(false)

    const [material] = useMaterial()

    const {position} = useSpring({
        position: location === 'MEASUREMENTS' ? [0,-6,0] : [0,0,0],
        config: config.molasses,
    });

    const [
        normalMap,
        noiseMap,
        charger_emissive_map,
    ] = useLoader(TextureLoader, [
        plastic_normal,
        noise,
        charger_emissive
    ]);

    normalMap.wrapS = RepeatWrapping;
    normalMap.wrapT = RepeatWrapping;
    //normalMap.repeat = new Vector2(2,2);

    noiseMap.wrapS = RepeatWrapping;
    noiseMap.wrapT = RepeatWrapping;

    const main = useRef(document.createElement('canvas'))
    main.current.width = 512;
    main.current.height = 256;
    const ctx = useRef(main.current.getContext('2d'));
    const diffuse = useRef(new CanvasTexture(ctx.current.canvas));

    useEffect(() => {
        let f = new FontFace('myFont', `url(${font})`);
        f.load().then(function (font) {
            document.fonts.add(font);
            setFontLoaded(true)
        });
    }, [])

    const charWidth = 90;
    const colWidth = 35;
    const base = [140, 180]

    useEffect(() => {
        power ? clock.start() : clock.stop()
    }, [power])

    useFrame((state, delta) => {
        if (!fontLoaded) return
        ctx.current.clearRect(0, 0, main.current.width, main.current.height);
        ctx.current.fillStyle = 'white';
        ctx.current.font = "190px myFont";
        ctx.current.textAlign = 'right'
        clock.getDelta()
        if (clock.elapsedTime > 120) clock.start()
        const minutes = Math.floor((elapsedTime || clock.elapsedTime) / 60).toString().split("")
        const seconds = (Math.floor((elapsedTime || clock.elapsedTime)) % 60).toString().split("")
        ctx.current.fillText(minutes.length > 1 ? minutes[0] : '0', base[0], base[1]);
        ctx.current.fillText(minutes.length > 1 ? minutes[1] : minutes[0], base[0] + charWidth * 1, base[1]);
        ctx.current.fillText(':', base[0] + charWidth * 1 + colWidth, base[1]);
        ctx.current.fillText(seconds.length > 1 ? seconds[0] : '0', base[0] + charWidth * 2 + colWidth, base[1]);
        ctx.current.fillText(seconds.length > 1 ? seconds[1] : seconds[0], base[0] + charWidth * 3 + colWidth, base[1]);
        diffuse.current.needsUpdate = true;
        //location === 'charger' && group.current.rotateY(snap.controls.move[0] / 3000)
    }, [])

    return (
        <animated.group dispose={null} ref={group} position={position}>
            <mesh geometry={nodes.charger.geometry}>
                <meshPhysicalMaterial
                    color={material === 0 ? 0x172129 : 0xf2f2f2}
                    roughness={0.55}
                    ior={1}
                    metalness={0}
                    reflectivity={0.7}
                    transmission={0.1}
                    transmissionMap={noiseMap}
                    side={FrontSide}
                />
                <mesh geometry={nodes.display.geometry}>
                    <meshPhysicalMaterial
                        color={material === 0 ? 0x172129 : 0xf2f2f2}
                        roughness={0.05}
                        ior={1}
                        metalness={0}
                        reflectivity={0.3}
                        transmission={0.1}
                        side={FrontSide}
                        emissive={power ? 0xffffff : 0x000000}
                        emissiveMap={diffuse.current}
                        emissiveMap-flipY={false}
                    />
                </mesh>

                <LightsManager />

                <mesh geometry={nodes.lights_dot_1.geometry} visible={false}>
                    <animated.meshStandardMaterial color={'blue'} alphaMap={charger_emissive_map} alphaMap-flipY={false} transparent={true} emissive={'blue'}/>
                </mesh>

                <mesh geometry={nodes.lights_dot_2.geometry} visible={false}>
                    <animated.meshStandardMaterial color={'blue'} alphaMap={charger_emissive_map} alphaMap-flipY={false} transparent={true} emissive={'blue'}/>
                </mesh>

                <mesh
                    geometry={nodes.logo.geometry}
                    material={materials["Material.001"]}
                />
            </mesh>
        </animated.group>
    )
}

export default Charger
