import React, {useEffect, useMemo} from 'react';
import {Vector3, MathUtils, BufferGeometry, BufferAttribute, TextureLoader, AdditiveBlending} from 'three';
import { useFrame, useLoader } from '@react-three/fiber';
import alphaImage from '../../assets/images/point_particle.jpg';

// inspiration from
// https://jsfiddle.net/h9fnx3sy/

const maxParticles = 2500

function PointParticles({ count, spawnSpread = 1, speed = 0.1, lifetime = 0.8, ...props }) {

    const _rotationCache = useMemo(() => {

        const temp = []

        for (let i = 0; i < maxParticles; i++) {

            temp.push(new Vector3().random().subScalar( 0.5 ).normalize().clamp({x: -1,y:-1,z:-0.1}, {x: 1,y:1,z:0.1}))

        }

        return temp
    }, [])

    const attributes = useMemo(() => {

        const positions = new Float32Array(maxParticles*3);
        const ages = new Float32Array(maxParticles);
        const lifetimes = new Float32Array(maxParticles);

        for (let i = 0; i < count; i++) {
            positions[i * 3 + 0] = MathUtils.randFloatSpread(spawnSpread);
            positions[i * 3 + 1] = MathUtils.randFloatSpread(spawnSpread);
            positions[i * 3 + 2] = MathUtils.randFloatSpread(spawnSpread);

            ages[i] = MathUtils.randFloat(0, lifetime);
            lifetimes[i] = MathUtils.randFloat(0, lifetime);

        }

        return {positions, ages, lifetimes};

    }, []);

    const bufferGeo = useMemo(() => new BufferGeometry(),[]);

    const geo = useMemo(() => bufferGeo
            .setAttribute('position', new BufferAttribute(attributes.positions, 3) )
            .setAttribute( 'age', new BufferAttribute(attributes.ages, 1 ) )
            .setAttribute( 'lifetime', new BufferAttribute(attributes.lifetimes, 1 ) )
        , [attributes, bufferGeo]);

    useEffect(() => {
        geo.setDrawRange(0, count)
    }, [count])

    useFrame((stuff, delta) => {

        for ( let i = 0; i < count; i ++ ) {

            geo.attributes.position.array[ i*3+0 ] += speed * _rotationCache[i].x;
            geo.attributes.position.array[ i*3+1 ] += speed * _rotationCache[i].y;
            geo.attributes.position.array[ i*3+2 ] += speed * _rotationCache[i].z;

            geo.attributes.age.array[ i ] += delta;

            if (geo.attributes.age.array[ i ] > geo.attributes.lifetime.array[ i ]) {

                geo.attributes.position.array[ i*3+0 ] = MathUtils.randFloatSpread(spawnSpread);
                geo.attributes.position.array[ i*3+1 ] = MathUtils.randFloatSpread(spawnSpread);
                geo.attributes.position.array[ i*3+2 ] = MathUtils.randFloatSpread(spawnSpread);

                geo.attributes.age.array[ i ] = 0;

            }

        }

        geo.attributes.position.needsUpdate = true;

        geo.attributes.age.needsUpdate = true;

    });

    const [ alphaMap ] = useLoader(TextureLoader, [ alphaImage ]);

    return (
        <points args={[geo]} {...props}>
            <pointsMaterial
                color={[0,0.75,1]}
                size={0.2}
                alphaMap={alphaMap}
                //emissive={[0,0.75,1]}
                blending={AdditiveBlending}
                depthTest={true}
                alphaTest={0.05}
                transparent={true}
            />
        </points>
    )
}

export default PointParticles
