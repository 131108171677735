import { useMemo } from "react";
import {
  useBrush,
  useMaterial,
  useTranslatedText,
  useBrushhead,
} from "../hooks";

const Tutorial = () => {
  const [TEXT, language] = useTranslatedText();

  const [{ data }] = useBrush();

  const [, setColor] = useBrushhead();

  const [material, setMaterial] = useMaterial();

  const onColorChange = (i) => {
    if (i === material) return false;

    setMaterial(i);

    setColor(i === 2 ? 1 : i);
  };

  const materials = useMemo(() => {
    //Filter out white materil for japan
    if (language == "jp-JP") {
      onColorChange(0);
      return data.materials.filter((m) => m.key != "TEN_MATERIAL_STARDUST");
    }

    return data.materials;
  }, [data.materials, language]);

  return (
    <div className={"colorpicker d-flex flex-lg-column mb-2"}>
      {materials.map((m, i) => (
        <button
          className={`btn btn-transparent colorpicker-button d-flex flex-column align-items-center ${
            i === material ? "selected" : ""
          }`}
          onClick={() => onColorChange(i)}
          key={i}
        >
          <span
            className={"colorpicker-pill mb-2"}
            style={{ background: m.color }}
          >
            {" "}
          </span>
          <span className={"colorpicker-text"}>{TEXT[m.key]}</span>
        </button>
      ))}
    </div>
  );
};

export default Tutorial;
