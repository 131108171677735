import React, { useState, useRef, useEffect } from "react";
import { config, useSpring, animated } from "react-spring";
import { useTranslatedText, useHashLocation } from "../hooks";

function ScrollWrapper({ redirect = "/", cover = true, children, ...props }) {
  const [TEXT] = useTranslatedText();

  const [, setLocation] = useHashLocation();

  const [open, setOpen] = useState(false);

  const { transform, opacity } = useSpring({
    to: {
      transform: open
        ? "translateY(0vh) scale(100%,100%)"
        : "translateY(5vh) scale(90%,90%)",
      opacity: open ? 1 : 0,
    },
    config: config.gentle,
  });

  return (
    <>
      {children && (
        <div className={"position-absolute w-100"} style={{ bottom: "5vh" }}>
          <div className="container">
            <div className="row">
              <div className="col d-flex justify-content-center">
                <button
                  className={"btn btn-light btn-lg"}
                  onClick={() => setOpen(true)}
                >
                  {TEXT.MORE_INFO}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="position-absolute mt-3"
        style={{ top: 0, left: 0, width: "100%" }}
      >
        <div className={"container-xxl"}>
          <div className="row mt-0 justify-content-between">
            <div
              className="col d-flex align-items-center"
              style={{ height: "4rem", maxWidth: "4rem" }}
            >
              <button
                className={"btn btn-link p-0"}
                onClick={() => setLocation(redirect)}
                type="button"
                aria-label="Close"
                style={{ position: "relative" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27.316"
                  height="27.316"
                  viewBox="0 0 27.316 27.316"
                  className={"img-fluid"}
                  style={{ width: "2rem" }}
                >
                  <path
                    id="arrow-left_2_"
                    data-name="arrow-left(2)"
                    d="M31.476,16.014v3.449H10.782l9.485,9.485L17.818,31.4,4.16,17.738,17.818,4.08l2.449,2.449-9.485,9.485Z"
                    transform="translate(-4.16 -4.08)"
                    fill="#fff"
                  />
                </svg>
              </button>
            </div>

            <div className="col" style={{ marginTop: "-5px" }}>
              <h3 className={"content-headline"}>{props.headline}</h3>
            </div>
          </div>
        </div>
      </div>

      <div
        className="scroll-wrapper d-flex justify-content-center align-items-center"
        style={{
          top: "0rem",
          left: "0rem",
          width: "100%",
          position: open ? "static" : "fixed",
          pointerEvents: open ? "all" : "none",
          overflowY: open ? "auto" : "hidden",
          overflowX: "hidden",
        }}
      >
        <animated.div
          className={"scroll-container container-fluid pt-5"}
          style={{ transform, opacity }}
        >
          <div className="container-xxl position-relative">
            <div className="row">
              <div className="col-xl-6 mx-auto">{children}</div>
            </div>
          </div>
        </animated.div>

        <animated.div
          className="d-flex justify-content-center position-fixed"
          style={{ bottom: "5vh", opacity, pointerEvents: "none" }}
        >
          <button
            className={"btn btn-secondary btn-lg"}
            style={{ pointerEvents: open ? "all" : "none" }}
            onClick={() => setOpen(false)}
          >
            {TEXT.CLOSE}
          </button>
        </animated.div>
      </div>
    </>
  );
}

export default ScrollWrapper;
