import React, {useEffect} from 'react';
import {useTranslatedText, useControlOptions, useSpeed, useDissolve, useBrush} from "../hooks";
import {ScrollWrapper} from "../components";
import magneticImage from "../../assets/images/scroll_magnetic_brush.jpg";

function MagneticDrive() {

    const [, {setBrushRotation, setBrushPosition}] = useBrush()

    const [TEXT] = useTranslatedText();

    const [, {enable, move}] = useControlOptions();

    const [, setSpeed] = useSpeed();

    const [,setXPosition] = useDissolve();

    useEffect(() => {

        setBrushRotation([0,Math.PI,0]);

        setBrushPosition([0,0,0]);

        enable(true);

        move([0,16,20]);

        setSpeed(5);

        setXPosition(9);

        return () => setXPosition(26);

    }, []);

    return (

        <ScrollWrapper
            redirect={'SCENE_TECHNOLOGY'}
            headline={TEXT.MAGNETIC_HEAD}
        >

            <div className={'row'}>
                <div className="col-8 mb-5">
                    <img src={magneticImage} alt="" />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <p>{TEXT.MAGNETIC_DESC}</p>
                </div>
            </div>

        </ScrollWrapper>

    )

}

export default MagneticDrive
