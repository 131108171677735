import { useButtons } from "../hooks";
import { animated, config, useSpring } from "@react-spring/three";
import React from "react";
import { A11yAnnouncer, A11y, useA11y } from "@react-three/a11y";

const ButtonMaterial = () => {
  const [, , world] = useButtons();

  const spring = useSpring({
    from: { envMapIntensity: 0 },
    to: { envMapIntensity: world },
    config: config.molasses,
  });

  const a11y = useA11y();

  return (
    <animated.meshPhysicalMaterial
      color={a11y.focus ? "orangered" : "black"}
      name={"Button"}
      clearcoat={1.0}
      transmission={0.1}
      transparent={true}
      envMapIntensity={a11y.focus ? 1 : spring.envMapIntensity}
    />
  );
};

export default ButtonMaterial;
