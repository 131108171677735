import create from 'zustand';

const useStore = create(set => {
    return {
        xPosition: 26,
        setXPosition: (value) => set((state) => ({xPosition: value})),
    }
})

const useDissolve = () => {

    return [
        useStore(state => state.xPosition),
        useStore(state => state.setXPosition),
    ];

};

export default useDissolve;