import data from '../data/series.json';
import useUrlParams from "./use-url-params.js";

function getImageUrl(name) {
    return new URL(`../../assets/images/${name}`, import.meta.url).href
}

const useSeries = () => {

    const params = useUrlParams()

    return {
        display: {
            image_1: getImageUrl(data[params.series].info.display.image_1)
        },
        sensor: {
            image_1: getImageUrl(data[params.series].info.sensor.image_1),
            image_2: getImageUrl(data[params.series].info.sensor.image_2),
        },
        charger: {
            ...data[params.series].info.charger,
            image_1: getImageUrl(data[params.series].info.charger.image_1),
            image_2: getImageUrl(data[params.series].info.charger.image_2),
        },
    };

};

export default useSeries;
