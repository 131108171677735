import { useState, useEffect } from 'react';

// returns the current hash location in a normalized form
// (excluding the leading '#' symbol)
const currentLocation = () => {
    return window.location.hash.replace(/#/, "") || "/";
};

const navigate = (to) => (window.location.hash = to);

const pageAccessedByReload = (
    (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)
        .includes('reload')
);

const useHashLocation = () => {

    const [loc, setLoc] = useState(currentLocation());

    useEffect(() => {

        if (pageAccessedByReload) window.location.href = ''

        const handler = () => {setLoc(currentLocation())};

        window.addEventListener("hashchange", handler);

        return () => window.removeEventListener("hashchange", handler);

    }, []);

    return [loc, navigate];
};

export default useHashLocation;
