import React, { useMemo, useState } from 'react';
import {Raycaster, Vector3, Plane} from 'three';
import { useThree } from '@react-three/fiber';
import {Html} from '@react-three/drei/web/Html';
import { animated, config, useSpring } from '@react-spring/three';
import { a } from '@react-spring/web';
import { useGesture } from 'react-use-gesture';
import { useSpeed } from "../hooks";
import {GlowingRing} from "../models";

// this one helped me finally https://jsfiddle.net/dkbvxuez/

export default function Draggable({ scale = 1.0, defaultSpeed = 4 }) {

    const offset = 9.5;

    const min = offset;

    const max = offset + 2.5;

    const z = -4.25;

    const { size, camera } = useThree();

    const [position, setPosition] = useState([0, 2.5, 0]);

    const [dragging, setDragging] = useState(false);

    const [,setSpeed] = useSpeed();

    const spring = useSpring({
        from: { position: [0, 2.5, 0] },
        to: { position: position },
        config: config.wobbly,
    });

    const raycaster = new Raycaster();

    const intersects = new Vector3();

    const plane = useMemo(() => new Plane( new Vector3( 0, 0, 1 ), -z ), [z])

    let distance = max-min;

    const bind = useGesture({
        onDragStart: () => setDragging(true),
        onDrag: ({ event }) => {

            raycaster.setFromCamera({
                x: (event.offsetX / size.width) * 2 - 1,
                y: -(event.offsetY / size.height) * 2 + 1,
            }, camera);

            raycaster.ray.intersectPlane(plane, intersects);

            distance = Math.abs(intersects.y - offset);

            setSpeed(intersects.y < offset ? Math.sqrt(1.5*distance)+4 : defaultSpeed);

            setPosition([
                    Math.min(Math.max(intersects.x, -0.5), 0.5),
                    Math.min(Math.max(intersects.y - offset, 0), 2.5) - Math.sqrt(distance)/10,
                    scale/2,
            ]);

        },
        onDragEnd: () => {

            setPosition([0, 2.5, scale/2])

            setSpeed(defaultSpeed);

            setDragging(false);

        }
    })

    const { o } = useSpring({
        from: {
            o: 1,
        },
        o: dragging ? 0 : 1,
        config: config.gentle,
    });

    return (
        <group position={[0,offset,z]}>
            <animated.mesh
                position={spring.position}
                {...bind()}
            >
                <dodecahedronBufferGeometry args={[scale, 6]} />
                <meshPhysicalMaterial
                    color={0x45105c}
                    emissiveIntensity={0.1}
                    emissive={'#621485'}
                    clearcoat={1}
                    clearcoatRoughness={0.1}
                />

                {!dragging && <GlowingRing position={[0, 0, 0]} progress={100} scale={1.55}/>}
            </animated.mesh>



            {false && <mesh visible={false} position={[0, 2.75, 0]}>
                <Html center sprite style={{pointerEvents: "none"}}>

                    <a.svg xmlns="http://www.w3.org/2000/svg" width="27.316" height="141.711" viewBox="0 0 27.316 141.711" style={{
                        transform: 'translate(0, 50%)',
                        opacity: o
                    }}>
                        <path id="arrow-left_2_" data-name="arrow-left(2)" d="M9.087,17.738l11.18,11.209L17.818,31.4,4.16,17.738,17.818,4.08l2.449,2.449Z" transform="translate(-4.08 145.871) rotate(-90)" fill="#fff"/>
                        <circle id="Ellipse_148" data-name="Ellipse 148" cx="13" cy="13" r="13" transform="translate(1)" fill="#fff"/>
                        <path id="Pfad_223" data-name="Pfad 223" d="M1.5,124h-3V0h3Z" transform="translate(13.5 13)" fill="#fff" opacity="0.5"/>
                    </a.svg>

                </Html>
            </mesh>}
        </group>
    )

}
