import React, {useEffect} from 'react';
import {useTranslatedText, useControlOptions, useDisplay, useBrush, useSeries} from "../hooks";
import {ScrollWrapper} from "../components";

export const order = [
    'HELLO_SMILE_2',
    'HELLO_SMILE_1',
    'HELLO_SUN',
    'GOOD_MORNING',
    'GOOD_NIGHT',
    'RING_LIGHT',
    'SETTINGS_2',
    'DAILY_CLEAN_TEETH',
    'INTENSE',
    'WHITEN',
    'SENSITIVE',
    'SUPER_SENSITIVE',
    'GUM_CARE',
    'TONGUE_CLEAN',
    'TIME',
    'LOAD',
    'REFILL_IS_USED_UP',
    'IO',
];

const SNIPPETS = [
    'DISPLAY_MODE_0',
    'DISPLAY_MODE_0',
    'DISPLAY_MODE_0_1',
    'DISPLAY_MODE_0_1',
    'DISPLAY_MODE_0_1',
    'DISPLAY_MODE_1',
    'DISPLAY_MODE_2',
    'DISPLAY_MODE_3_1',
    'DISPLAY_MODE_3_2',
    'DISPLAY_MODE_3_3',
    'DISPLAY_MODE_3_4',
    'DISPLAY_MODE_3_5',
    'DISPLAY_MODE_3_6',
    'DISPLAY_MODE_3_7',
    'DISPLAY_MODE_4',
    'DISPLAY_MODE_5',
    'DISPLAY_MODE_6',
];

function Display() {

    const [, {setBrushRotation, setBrushPosition}] = useBrush()

    const [TEXT] = useTranslatedText();

    const series = useSeries()

    const [, {move, enable}] = useControlOptions();

    const [display] = useDisplay();

    useEffect(() => {

        setBrushRotation([0,0,0]);

        setBrushPosition([0,0,0]);

        enable(false);

        move([0,12,15]);

    }, []);

    return (

        <>

            <div style={{position: 'absolute', top: '50%', transform: 'translate(0, -50%)',left: '65%', color: 'white', fontSize: '1.6rem', textAlign: 'left', width: '25%'}}>
                <p>{TEXT[SNIPPETS[order.indexOf(display)]]}</p>
            </div>

            <ScrollWrapper
                redirect={'SCENE_TECHNOLOGY'}
                headline={TEXT.DISPLAY_HEAD}
            >

                <div className={'row mb-5'}>
                    <div className="col">
                        <img src={series.display.image_1} alt="" />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col">
                        {TEXT.DISPLAY_DESC}
                    </div>
                </div>

            </ScrollWrapper>

        </>

    )

}

export default Display
