import React, {useEffect, useState} from 'react';
import {useTranslatedText, useControlOptions, useSpeed, useBrush} from "../hooks";
import {ScrollWrapper} from "../components";
import brushImage from "../../assets/images/scroll_io_brush.jpg";

function IOTechnology() {

    const [, {setBrushRotation, setBrushPosition}] = useBrush()

    const min = 5;

    const max = 10;

    const [value, setValue] = useState(max / 2);

    const [TEXT] = useTranslatedText();

    const [, {move, enable}] = useControlOptions();

    const [, setSpeed] = useSpeed();

    useEffect(() => {

        enable(false)

        setBrushRotation([Math.PI/1.6,Math.PI,0]);

        setBrushPosition([0,20,-15]);

        move([0,12,15]);

    }, []);

    const handleChange = (e) => {

        setValue(e.target.value)

    };

    useEffect(() => {

        setSpeed(value)

    }, [value, setSpeed])

    return (

        <>

            <div className="" style={{position: "absolute", bottom: "12rem", width: "100%"}}>
                <div className="container px-4">
                    <div className="row px-4" style={{color: "white"}}>
                        <div className="col-6">
                            <p className={'m-0'} style={{textAlign: "left", textTransform: 'uppercase'}}>{TEXT.SLOW}</p>
                        </div>
                        <div className="col-6">
                            <p className={'m-0'} style={{textAlign: "right", textTransform: 'uppercase'}}>{TEXT.FAST}</p>
                        </div>
                    </div>
                    <div className="row px-4">
                        <div className="col">
                            <input
                                type="range"
                                className={'form-range'}
                                min={min}
                                max={max}
                                step={0.2}
                                value={value}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ScrollWrapper
                redirect={'SCENE_TECHNOLOGY'}
                headline={TEXT.TECHNOLOGY_HEAD}
            >

                <div className={'row mb-5'}>
                    <div className="col">
                        <img src={brushImage} alt="" />
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col">
                        <p>{TEXT.TECHNOLOGY_DESC}</p>
                    </div>
                </div>

            </ScrollWrapper>

        </>

    )

}

export default IOTechnology
