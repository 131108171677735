import { useRef } from "react";
import { extend, useFrame } from "@react-three/fiber";
import { Color, RepeatWrapping } from "three";
import texture from "../../assets/images/noise_seamless.jpg";
import { useTexture, shaderMaterial } from "@react-three/drei";
import glow_frag from "../materials/glow.frag";
import basic_vert from "../materials/basic.vert";

const parameters = {
  innerRadius: 0.65,
  outerRadius: 1.0,
  thetaSegments: 50,
  phiSegments: 2,
  thetaStart: 0,
  thetaLength: Math.PI * 2,
  glowRadius: 0.725,
};

const GlowMaterial = shaderMaterial(
  {
    uTime: 0,
    alphaTexture: null,
    color: new Color(1.0, 1.0, 1.0),
    innerRadius: parameters.innerRadius,
    outerRadius: parameters.outerRadius,
    glowRadius: parameters.glowRadius,
  },
  basic_vert,
  glow_frag
);

extend({ GlowMaterial });

const GlowingRing = ({
  position,
  rotationY,
  scale,
  progress = 100,
  power = 0,
}) => {
  const material = useRef();

  const alphaMap = useTexture(
    texture,
    (texture) => (texture.wrapS = texture.wrapT = RepeatWrapping)
  );

  useFrame(
    ({ clock }) => (material.current.uniforms.uTime.value = clock.elapsedTime)
  );

  return (
    <mesh position={position} rotation-x={rotationY} scale={scale}>
      <ringGeometry args={Object.values(parameters)} />
      <glowMaterial
        ref={material}
        alphaTexture={alphaMap}
        color={"#0080ff"}
        transparent={true}
      />
    </mesh>
  );
};

export default GlowingRing;
