import create from "zustand";
import React, { useEffect, useMemo, useState } from "react";
import defaultText from "../../assets/lang/en-US.jsx";

export const useStoreLang = create((set) => ({
  language: "en-US",
  setLanguage: (value) => {
    return set(() => ({ language: value }));
  },
  text: defaultText,
  setText: (text) => set({ text: text }),
}));

const useTranslatedText = () => {
  return [
    useStoreLang((state) => state.text),
    useStoreLang((state) => state.language),
    useStoreLang((state) => state.setLanguage),
  ];
};

export default useTranslatedText;
