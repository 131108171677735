import React, {Suspense, useEffect} from "react";
import { Housing, Brushheads } from './';
import {useSpring, animated, config, useSpringRef} from '@react-spring/three';
import { Router, Route } from 'wouter';
import {useHashLocation, useSpeed, useBrush, useMaterial, usePrevious, useTranslatedText} from "../hooks";
import {Inner} from "./";
import { Html } from "@react-three/drei/web/Html";

const Label = ({thresholds = [4.8,5.2]}) => {
    const [TEXT] = useTranslatedText();
    const [speed] = useSpeed();
    if(speed <= thresholds[0]) return <span>{TEXT.SENSOR_SOFT}</span>
    if(speed > thresholds[0] && speed <= thresholds[1]) return <span>{TEXT.SENSOR_PERFECTION}</span>
    if(speed > thresholds[1]) return <span>{TEXT.SENSOR_HARD}</span>
};

export default function Brush() {

    const [{brushPosition, brushRotation, brushSpringConfig}] = useBrush()

    const [material] = useMaterial()

    const prevMaterial = usePrevious(material)

    const springRef = useSpringRef()

    const { rotation, position } = useSpring({
        rotation: brushRotation,
        position: brushPosition,
        reset: brushSpringConfig.reset,
        config: config[brushSpringConfig.config],
    });

    const { swirl } = useSpring({
        from: { swirl: [0,0,0] },
        to: { swirl: [0, Math.PI*2, 0] },
        reset: true,
        config: config.wobbly,
        ref: springRef,
    });

    useEffect(() => {
        if (prevMaterial === undefined) return
        springRef.start()
    }, [material])

    return (

        <animated.group
            rotation={rotation}
            position={position}
        >

            <animated.group rotation={swirl}>

                <Suspense fallback={null}>
                    <Housing/>
                </Suspense>

                <Suspense fallback={null}>
                    <Brushheads/>
                </Suspense>

                <Router hook={useHashLocation}>

                    <Route path={'MAGNETIC_HEAD'}>

                        <Suspense fallback={null}>
                            <Inner/>
                        </Suspense>

                    </Route>

                    <Route path={'SENSOR_HEAD'}>

                        <mesh position={[-1,14.5,-1]} visible={false}>
                            <sphereGeometry args={[0.5]}/>
                            <Html>
                                <div className={`hotspot hotspot-right`} style={{opacity: 1}}>
                                    <div className="hotspot-label" style={{whiteSpace: 'nowrap'}}>
                                        <Label/>
                                    </div>
                                </div>
                            </Html>
                        </mesh>

                    </Route>

                </Router>

            </animated.group>

        </animated.group>

    )

}
