import React, {useRef, useEffect} from 'react';
import {useThree} from "@react-three/fiber";
import {PerspectiveCamera as ThreePerspectiveCamera, Vector3} from 'three';
import {OrbitControls} from "@react-three/drei/core/OrbitControls";
import {animated, config, useSpring} from "@react-spring/three";
import {useControlOptions} from "../hooks";

function Dolly() {

    const {camera} = useThree();

    const [options] = useControlOptions();

    const controls = useRef();

    const fakeCamera = useRef(new ThreePerspectiveCamera(20))

    const fakeTarget = useRef(new Vector3(...options.target))

    const {target} = useSpring({
        position: options.position,
        target: options.target,
        config: config.molasses,
        onStart: () => {
            controls.current.enabled = false
        },
        onChange: ({value}) => {
            camera.position.set(...value.position)
            fakeTarget.current.set(...value.target)
            camera.lookAt(fakeTarget.current)
        },
        onRest: ({value}) => {
            controls.current.object.position.copy(camera.position)
            controls.current.target.copy(fakeTarget.current)
            if (options.enabled) controls.current.enabled = true
        },
    })

    useEffect(() => {
        fakeCamera.current.position.set(...options.position)

        controls.current.addEventListener('change', () => {
            camera.position.lerp(fakeCamera.current.position, 1)
            camera.quaternion.slerp(fakeCamera.current.quaternion, 1)
        });
        controls.current.update()
    }, [])

    return (
        <>
            <OrbitControls
                camera={fakeCamera.current}
                ref={controls}
                target={fakeTarget.current}
                zoomSpeed={0.5}
                minDistance={14}
                maxDistance={50}
                minPolarAngle={0.5}
                maxPolarAngle={Math.PI-0.5}
                enabled={false}
            />
            <animated.axesHelper args={[5]} position={target} visible={false} />
        </>
    )

}

export default Dolly
