import React, {useEffect} from 'react';
import {useTranslatedText, useControlOptions} from "../hooks";
import {ScrollWrapper} from "../components";
import womanImage from "../../assets/images/scroll_app_woman.jpg";
import zonesImage from "../../assets/images/scroll_app_zones.jpg";
import appstoreDe from "../../assets/images/appstore_badge_de.svg";
import appstoreEn from "../../assets/images/appstore_badge_en.svg";
import appstoreFr from "../../assets/images/appstore_badge_fr.svg";
import playstoreDe from "../../assets/images/playstore_badge_de.svg";
import playstoreEn from "../../assets/images/playstore_badge_en.svg";
import playstoreFr from "../../assets/images/playstore_badge_fr.svg";

const appstoreBadges = {
    'de-DE': appstoreDe,
    'en-US': appstoreEn,
    'fr-FR': appstoreFr,
}

const playstoreBadges = {
    'de-DE': playstoreDe,
    'en-US': playstoreEn,
    'fr-FR': playstoreFr,
}

function OralbApp() {

    const [TEXT, language] = useTranslatedText();

    const [, {move, enable, lookAt}] = useControlOptions();

    useEffect(() => {

        move([0,12,52]);

        lookAt([-6.5,11.5,0])

        enable(false);

    }, []);

    return (

        <ScrollWrapper
            redirect={'SCENE_TECHNOLOGY'}
            headline={TEXT.APP_HEAD}
        >

            <div className={'row mb-5'}>
                <div className="col-6">
                    <img src={womanImage} alt="" />
                </div>
                <div className="col-6">
                    <img src={zonesImage} alt="" />
                </div>
            </div>

            <div className="row mb-5">
                <div className="col">
                    {TEXT.APP_DESC}
                </div>
            </div>

            <div className="row mb-5">
                <div className="col-6 d-flex justify-content-end">
                    <img src={appstoreBadges[language]} style={{height: '4rem', width: 'auto'}} alt=""/>
                </div>
                <div className="col-6">
                    <img src={playstoreBadges[language]} style={{height: '4rem', width: 'auto'}} alt=""/>
                </div>
            </div>

        </ScrollWrapper>

    )

}

export default OralbApp
